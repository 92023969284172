<template>
	<div>
		<div :class="ismobile ? 'min_content': 'content'">
			<div :class="ismobile ? 'min_introduce': 'introduce'">
				<div :class="ismobile ? 'min_title': 'title'" v-show="enchange">{{title}}</div>
				<div :class="ismobile ? 'min_title': 'title'" v-show="!enchange">{{entitle}}</div>
				<div class="titleen" v-show="enchange">Shaanxi Digital Performing Arts Technology Co</div>
				<div :class="ismobile ? 'min_text': 'text'" v-show="enchange">
					{{content}}
				</div>
				<div :class="ismobile ? 'min_text_en': 'text'" v-show="!enchange">
					{{encontent}}
				</div>
			</div>
			<div :class="ismobile ? 'min_model': 'model'">
				<div>
					<div class="model_1" v-if="!ismobile">
						<modelone :murl='"glb/9.glb"' :widthheight="400" :contname='"model_one"'></modelone>
					</div>
					<div :class="ismobile ? 'min_model_2': 'model_2'">
						<modeltwo :murl='"glb/t4.glb"' :widthheight="200" :contname='"model_two"'></modeltwo>
					</div>
					<div :class="ismobile ? 'min_model_3': 'model_3'">
						<modelthree :murl='"glb/t2.glb"' :widthheight="200" :contname='"model_three"'></modelthree>
					</div>
					<div class="model_4" v-if="!ismobile">
						<modelfour :murl='"glb/t5.glb"' :widthheight="400" :contname='"model_four"'></modelfour>
					</div>
					<div :class="ismobile ? 'min_model_1': 'model_5'">
						<modelfive :murl='"glb/t5.glb"' :widthheight="200" :contname='"model_five"'></modelfive>
					</div>

					<div class="model_6" v-if="!ismobile">
						<modelsix :murl='"glb/t4.glb"' :widthheight="100" :contname='"model_six"'></modelsix>
					</div>
				</div>
			</div>
		</div> 
	</div>
</template>

<script>
	// import Ball from "./Ball.vue"
	import modelone from "./modelone.vue"
	import modeltwo from "./modeltwo.vue"
	import modelthree from "./modelthree.vue"
	import modelfour from "./modelfour.vue"
	import modelfive from "./modelfive.vue"
	import modelsix from "./modelsix.vue"
	export default {
		props: {
			ismobile: {
				type: Boolean,
				default: false
			},
			enchange: {
				type: Boolean,
				default: true
			}
		},
		components: {
			// Ball,
			modelone,
			modeltwo,
			modelthree,
			modelfour,
			modelfive,
			modelsix
		},
		data() {
			return {
				title: "数字陕演",
				entitle: "Shaanxi Digital Performing Arts Technology Co",
				content: "陕西数字演艺科技有限公司（数字陕演）在陕西省委宣传部的指导下成立于2022年11月1日，隶属陕西演艺集团。陕西演艺集团直属中共陕西省委宣传部，是省属国有独资大型文化演艺企业。公司专注于演艺文化行业数字化转型道路探索与发展，以“科技赋能 艺见美好”为理念，以行业数字化高质量发展为主线，以综合型管理人才和数字化、数智化、大数据、人工智能等高精尖技术为支撑，探索数字与创意，链接人文与情感。",
				encontent: "Ltd (Digital Shaanxi Performance) was established on November 1, 2022 under the guidance of the Propaganda Department of Shaanxi Provincial Party Committee and is subordinate to Shaanxi Performing Arts Group. Directly under the Propaganda Department of the CPC Shaanxi Provincial Party Committee, Shaanxi Performing Arts Group is a provincial state-owned wholly-owned large-scale cultural and performing arts enterprise. The company focuses on the exploration and development of the digital transformation of the performing arts and culture industry, with the concept of 'Technology Empowerment, Artistic Beauty', the main line of high-quality development of the industry's digitalization, and the support of comprehensive management talents and high-precision technologies such as digitization, digitization, big data, artificial intelligence, etc. The company explores the digitalization and creativity, and links the humanities and emotions.",
			}
		}
	}
</script>

<style scoped>
	.content {
		width: 100%;
		height: 100vh;
		position: relative;
		background: url("../assets/imgs/jsbg.png") no-repeat;
		background-size: cover;
		z-index: 6;
	}

	.min_content {
		width: 100%;
		height: auto;
		min-height: 100vh;
		position: relative;
		background: url("../assets/imgs/min_jsbg.png") no-repeat;
		background-size: cover;
		z-index: 6;
		display: flex;
		justify-content: center;
		align-items: center;
	}

	.model {
		width: 100%;
		height: 100%;
		position: absolute;
		top: 0;
		left: 0;
		z-index: 1;
	}
	.min_model {
		width: 100%;
		height: 100%;
		position: absolute;
		top: 0;
		left: 0;
		z-index: 1;
	} 

	.model>div {
		width: 100%;
		height: 100%;
		position: relative;
	}
	.min_model>div {
		width: 100%;
		height: 100%;
		position: relative;
		overflow: hidden;
	} 

	.model>div>div {
		width: auto;
		height: auto;
		position: absolute;
	}
	.min_model>div>div {
		width: auto;
		height: auto;
		position: absolute;
	} 

	.model_1 {
		bottom: 0;
		left: 500px;
	}
	.min_model_1 {
		bottom: 0px;
		left: -50px;
	}

	.model_2 {
		top: 55px;
		left: 450px;
	}
	.min_model_2 {
		bottom: -50px;
		right: -50px;
	}

	.model_3 {
		top: 90px;
		right: 400px;
	}
	
	.min_model_3 {
		top: 0;
		right: -50px;
	}

	.model_4 {
		bottom: 290px;
		left: 70px;
	}

	.model_5 {
		bottom: 50px;
		right: 150px;
	}

	.model_6 {
		top: 50%;
		right: 60px;
		transform: translateY(-50%);
	}

	.introduce {
		width: 916px;
		min-height: 488px;
		border-radius: 10px;
		display: flex;
		flex-direction: column;
		padding: 110px 170px;
		background: #FFFFFF;
		position: absolute;
		top: 50%;
		right: 139px;
		transform: translateY(-50%);
		box-sizing: border-box;
		/* z-index: 9; */
	}

	.min_introduce {
		width: 100%;
		height: 100%;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		padding: 40px 47px;
		box-sizing: border-box;
	}

	.title {
		font-size: 36px;
		font-weight: 500;
		line-height: 36px;
		letter-spacing: 0em;
		color: #E3A95F;
		margin-bottom: 10px;
	}

	.min_title {
		font-size: 36px;
		line-height: 36px;
		color: #E3A95F;
		width: 100%;
		margin-bottom: 11px;
	}

	.titleen {
		font-size: 18px;
		font-weight: normal;
		line-height: normal;
		letter-spacing: 0em;
		color: #333333;
		margin-bottom: 50px;
	}

	.text {
		font-size: 14px;
		font-weight: normal;
		line-height: 30px;
		letter-spacing: 0em;
		color: #333333;
	}

	.min_text {
		font-size: 12px;
		font-weight: normal;
		line-height: 34px;
		letter-spacing: 0em;
		color: #333333;
	}

	.min_text_en {
		font-size: 12px;
		font-weight: normal;
		line-height: 24px;
		letter-spacing: 0em;
		color: #333333;
	}
</style>