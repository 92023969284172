<template>
	<router-view v-slot="{ Component }">
		<keep-alive>
			<component :is="Component" v-if="$route.meta.keepAlive" />
		</keep-alive>
		<component :is="Component" v-if="!$route.meta.keepAlive" />
	</router-view>  
</template>
<script>
	import Mobile from "./utils/Mobile.js"
	export default {
		data() {
			return {

			}
		},
		mounted() {
			if (Mobile.isMobile()) {
				this.$router.push("/MinHome");
			} else {
				this.$router.push("/");
			}
		},
		methods: {

		}
	}
</script>

<style>
	body {
		margin: 0;
	}

	body::-webkit-scrollbar {
		display: none;
	}

	ul {
		padding: 0;
		margin: 0;
	}

	li {
		list-style: none;
	}

	img {
		width: 100%;
		height: 100%;
	}

	a {
		text-decoration: none;
	}
</style>