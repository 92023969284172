export default {
	isMobile: function() {
		let ismobile = null;
		let flag = navigator.userAgent.match(
			/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
		); 
		if(flag) {
			ismobile = true;
		} else {
			ismobile = false;
		}
		return ismobile;
	} 
}