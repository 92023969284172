<template>
	<div>
		<div :class="ismobile ?'min_content': 'content'">
			<div class="min_conatactus" v-if="ismobile">
				<el-collapse accordion>
					<el-collapse-item :title="enchange? lxtitle: enlxtitle" name="1">
						<div>
							<div class="min_left-item" v-for="(item,index) in lxdata" :key="index">
								<div class="min_left-item-left">
									<div class="min_item-title" v-show="enchange">{{item.title}}</div>
									<div class="min_item-title" v-show="!enchange">{{item.entitle}}</div>
									<div class="min_item-text">
										<div>{{item.phone}}</div>
										<div>{{item.email}}</div>
									</div>
								</div>
								<div class="min_left-item-right">
									<img :src="item.img" alt="">
								</div>
							</div>
						</div>
					</el-collapse-item>
					<el-collapse-item :title="enchange ? producttitle: enproducttitle" name="2">
						<ul class="min_productscase" v-show="enchange">
							<li class="min_productscase-item" v-for="(item,index) in productdata" :key="index"
								@click="productscasebtn(index)">
								{{item}}

								<div class="min_productscase-item-pop"
									v-if="productdatapop[index] && productscaseindex == index">
									{{productdatapop[index]}}

									<div class="min_productscase-item-pop-download">
										点击下载
										<img src="../assets/imgs/download.png" alt="" />
									</div>

									<div class="min-close" @click.stop="close">
										<img src="../assets/imgs/close.png" alt="" />
									</div>
								</div>
							</li>
						</ul>
						<ul class="min_productscase" v-show="!enchange">
							<li class="min_productscase-item" v-for="(item,index) in enproductdata" :key="index" @click="productscasebtn(index)">
								{{item}}
								
								<div class="min_productscase-item-pop"
									v-if="enproductdatapop[index] && productscaseindex == index">
									{{enproductdatapop[index]}}
								
									<div class="min_productscase-item-pop-download">
										Download
										<img src="../assets/imgs/download.png" alt="" />
									</div>
								
									<div class="min-close" @click.stop="close">
										<img src="../assets/imgs/close.png" alt="" />
									</div>
								</div>
							</li>
						</ul>
					</el-collapse-item>
					<el-collapse-item :title="enchange ? abouttitle: enabouttitle" name="3">
						<ul class="min_aboutus" v-show="enchange">
							<li class="min_aboutus-item" v-for="(item,index) in aboutdata" :key="index">{{item}}</li>
						</ul>
						<ul class="min_aboutus" v-show="!enchange">
							<li class="min_aboutus-item" v-for="(item,index) in enaboutdata" :key="index">{{item}}</li>
						</ul>
					</el-collapse-item>
					<el-collapse-item :title="enchange ? linktitle: enlinktitle" name="4">
						<ul class="min_link" v-show="enchange">
							<li class="min_link-item" v-for="(item,index) in linkdata" :key="index">
								<a :href="item.link" target="_blank">{{item.title}}</a>
							</li>
						</ul>
						<ul class="min_link" v-show="!enchange">
							<li class="min_link-item" v-for="(item,index) in enlinkdata" :key="index">
								<a :href="item.link" target="_blank">{{item.title}}</a>
							</li>
						</ul>
					</el-collapse-item>
				</el-collapse>
				<div>
					<ul class="min_followus">
						<li class="min_followus-item" v-for="(item,index) in followusdata" :key="index"
							@click="followbtn(item.link)">
							<a :href="item.link ? item.link:'javascript:;'">
								<img :src="item.img" alt="">
							</a>
							<div class="min_followus_pop" v-if="!item.link" v-show="followuspop">
								<img :src="item.url" alt="">
							</div>
						</li>
					</ul>
				</div>
			</div>

			<div class="conatactus" v-else>
				<div class="left">
					<div class="title" v-show="enchange">{{lxtitle}}</div>
					<div class="title" v-show="!enchange">{{enlxtitle}}</div>
					<div>
						<div class="left-item" v-for="(item,index) in lxdata" :key="index">
							<div class="left-item-left">
								<div class="item-title" v-show="enchange">{{item.title}}</div>
								<div class="item-title" v-show="!enchange">{{item.entitle}}</div>
								<div class="item-text">
									<div>{{item.phone}}</div>
									<div>{{item.email}}</div>
								</div>
							</div>
							<div class="left-item-right">
								<img :src="item.img" alt="">
							</div>
						</div>
					</div>
				</div>

				<div class="line"></div>
				<div class="right">
					<div>
						<div class="title" v-show="enchange">{{followtitle}}</div>
						<div class="title" v-show="!enchange">{{enfollowtitle}}</div>
						<ul class="followus">
							<li class="followus-item" v-for="(item,index) in followusdata" :key="index">
								<img :src="item.img" alt="">
								<div v-if="item.url" class="followus-item-hover">
									<img :src="item.url" alt="">
								</div>
							</li>
							<!-- <li class="" title="点击进入"><img src="../assets/imgs/about3.png" alt=""></li>
							<li class="" title="开通中，敬请期待"><img src="../assets/imgs/about2.png" alt=""></li>
							<li class="" title="开通中，敬请期待"><img src="../assets/imgs/about4.png" alt=""></li> -->
						</ul>
					</div>
					<div>
						<div class="title" v-show="enchange">{{producttitle}}</div>
						<div class="title" v-show="!enchange">{{enproducttitle}}</div>
						<ul class="productscase" v-show="enchange">
							<li class="productscase-item" v-for="(item,index) in productdata" :key="index">
								{{item}}

								<div class="productscase-item-pop" v-if="productdatapop[index]">
									{{productdatapop[index]}}

									<div class="productscase-item-pop-download">
										点击下载
										<img src="../assets/imgs/download.png" alt="" />
									</div>
								</div>
							</li>
						</ul>
						<ul class="productscase" v-show="!enchange">
							<li class="productscase-item" :class="{'enproductscase-item': !enchange}"
								v-for="(item,index) in enproductdata" :key="index">
								{{item}}

								<div class="productscase-item-pop" v-if="enproductdatapop[index]">
									{{enproductdatapop[index]}}

									<div class="productscase-item-pop-download">
										Download
										<img src="../assets/imgs/download.png" alt="" />
									</div>
								</div>
							</li>
						</ul>
					</div>
					<div>
						<div class="title" v-show="enchange">{{abouttitle}}</div>
						<div class="title" v-show="!enchange">{{enabouttitle}}</div>
						<ul class="aboutus" v-show="enchange">
							<li class="aboutus-item" v-for="(item,index) in aboutdata" :key="index">{{item}}</li>
						</ul>
						<ul class="aboutus" v-show="!enchange">
							<li class="aboutus-item" v-for="(item,index) in enaboutdata" :key="index">{{item}}</li>
						</ul>
					</div>
					<div>
						<div class="title" v-show="enchange">{{linktitle}}</div>
						<div class="title" v-show="!enchange">{{enlinktitle}}</div>
						<ul class="link" v-show="enchange">
							<li class="link-item" v-for="(item,index) in linkdata" :key="index">
								<a :href="item.link" target="_blank">{{item.title}}</a>
							</li>
						</ul>
						<ul class="link" v-show="!enchange">
							<li class="link-item" v-for="(item,index) in enlinkdata" :key="index">
								<a :href="item.link" target="_blank">{{item.title}}</a>
							</li>
						</ul>
					</div>
				</div>
			</div>
			<div :class="ismobile ? 'min_text': 'text'">
				<div>Copyright©陕西数字演艺科技有限公司 版权所有</div>
				<div><a href="https://beian.miit.gov.cn/#/Integrated/index" target="_blank">陕ICP备2022013856号-1</a></div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		props: {
			ismobile: {
				type: Boolean,
				default: false
			},
			enchange: {
				type: Boolean,
				default: true
			}
		},
		data() {
			return {
				lxtitle: "联系我们",
				enlxtitle: "Contact Us",
				lxdata: [{
					title: "商务合作",
					entitle: "Business Cooperation",
					phone: "029-87395817",
					email: "gqzxky6b2cv@aol.com",
					img: require("../assets/imgs/commerc.png")
				}, {
					title: "票务合作",
					entitle: "Ticket Cooperation",
					phone: "400-6800369",
					email: "gqzxky6b2cv@aol.com",
					img: require("../assets/imgs/ticket.png")
				}],
				followtitle: "关注我们",
				followusdata: [{
						img: require("../assets/imgs/about1.png"),
						url: require("../assets/imgs/douyin.png"),
						link: "https://www.douyin.com/user/MS4wLjABAAAA2CsbIqMCXOtyou6vlBsLynb_9IOcY3r6hryP11CKWuE"
					},
					{
						img: require("../assets/imgs/about3.png"),
						url: require("../assets/imgs/weixin.jpg"),
						link: ""
					},
					// {
					// 	img: require("../assets/imgs/about2.png"),
					// 	url: "",
					// 	link: ""
					// },
					// {
					// 	img: require("../assets/imgs/about4.png"),
					// 	url: "",
					// 	link: ""
					// }
				],
				followuspop: false,
				enfollowtitle: "Follow us",
				producttitle: "产品案例",
				enproducttitle: "Product Cases",
				productdata: ["艺数空间", "数字创作", "票务平台", "多媒体宣传", "文创销售", "西部文博会", "数字办公", "演艺大数据", "舞台检测", "文旅多模态", "重点会议",
					"多媒体舞剧", "申报系统", "舆情信息分析"
				],
				productdatapop: [null, null, null, null, null, null,
					'OA小程序依托现代互联网技术，实现集约化管理，并依托数字办公集成平台最终实现集团日常运营管理、重大项目申报、客户资源管理等集团运营的数字化管理和办公。', null, null, null,
					null, null, null, null
				],
				enproductdata: ["Art Numbers Space", "digital creation", "Ticketing Platform", "Multimedia campaigns",
					"Cultural and Creative Sales", "Western Cultural Expo", "digital office",
					"Big Data in Performing Arts", "Stage detection", "Cultural and Tourism Multimodal",
					"Focused meetings", "Multimedia Dance Theater", "Filing system",
					"Public Opinion Information Analysis"
				],
				enproductdatapop: [null, null, null, null, null, null,
					"OA applet relies on modern Internet technology to achieve intensive management, and relies on the digital office integration platform to ultimately realize the digital management and office of the Group's daily operation and management, major project declaration, customer resource management and other Group operations.",
					null, null, null,
					null, null, null, null
				],
				abouttitle: "关于我们",
				enabouttitle: "About Us",
				aboutdata: ["成立情况", "组织架构", "企业品宣"],
				enaboutdata: ["Establishment", "Organizational structure", "corporate branding"],
				linktitle: "友情链接",
				enlinktitle: "Friendly Link",
				linkdata: [{
					title: "陕西演艺集团",
					link: ""
				}, {
					title: "西安人民剧院",
					link: ""
				}, {
					title: "陕西文一国际",
					link: "http://topartsintermational.com"
				}],
				enlinkdata: [{
					title: "Shaanxi Performing Arts Group",
					link: ""
				}, {
					title: "Xi'an People's Theater",
					link: ""
				}, {
					title: "Shaanxi Wenyi Internationa",
					link: "http://topartsintermational.com"
				}],
				productscaseindex: -1,
			}
		},
		methods: {
			productscasebtn(index) {
				console.log(index);
				this.productscaseindex = index;
			},
			followbtn(link) {
				if (!link) {
					this.followuspop = !this.followuspop;
				}
			},
			close() {
				this.productscaseindex = -1;
			}
		}
	}
</script>

<style scoped>
	.content {
		width: 100%;
		height: 100vh;
		position: relative;
		display: flex;
		flex-direction: column;
		justify-content: end;
		z-index: 2;
		background: #fff;
	}

	.min_content {
		width: 100%;
		height: auto;
		position: relative;
		display: flex;
		flex-direction: column;
		justify-content: end;
		z-index: 2;
		background: #fff;
	}

	.conatactus {
		width: 100%;
		/* height: 686px; */
		background: #F7F5F5;
		/* padding: 142px 135px; */
		box-sizing: border-box;
		display: flex;
		justify-content: space-between;
		align-items: flex-end;
		height: auto;
		padding: 100px 135px;
	}

	.min_conatactus {
		width: 100%;
		height: auto;
		display: flex;
		flex-direction: column;
	}

	.min_conatactus::v-deep(.el-collapse) {
		border: none;
		background: #F7F5F5;
	}

	.min_conatactus::v-deep(.el-collapse-item) {
		-webkit-tap-highlight-color: transparent !important;
	}

	.min_conatactus::v-deep(.el-collapse-item__header) {
		margin: 0 20px;
		background: #F7F5F5;
		font-size: 14px;
		font-weight: normal;
		line-height: 14px;
		letter-spacing: 0em;
		color: #333333;
	}

	.min_conatactus::v-deep(.el-collapse-item__wrap) {
		padding: 0 20px;
		background: #F2EFEF;
	}

	/* .enconatactus {
		height: auto;
		padding: 100px 135px;
	} */

	.left {
		height: 100%;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
	}

	.title {
		font-size: 24px;
		font-weight: normal;
		line-height: 24px;
		letter-spacing: 0em;
		color: #333333;
		margin-bottom: 60px;
		text-align: center;
	}

	.left-item {
		height: 119px;
		display: flex;
		margin-bottom: 51px;
		padding-top: 14px;
		justify-content: space-between;
	}

	.min_left-item {
		height: auto;
		display: flex;
		justify-content: space-between;
		margin-bottom: 51px;
		padding-top: 14px;
	}

	.left-item:last-child {
		margin-bottom: 0;
		padding-top: 0;
	}

	.min_left-item:last-child {
		margin-bottom: 0;
		padding-top: 0;
	}

	.left-item-left {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		margin-right: 50px;
	}

	.min_left-item-left {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
	}

	.item-title {
		font-size: 24px;
		font-weight: 500;
		line-height: 24px;
		letter-spacing: 0em;
		color: #333333;
	}

	.min_item-title {
		font-size: 14px;
		line-height: 14px;
		color: #333333;
	}

	.item-text {
		font-size: 16px;
		font-weight: normal;
		line-height: 16px;
		letter-spacing: 0em;
		color: #221714;
	}

	.min_item-text {
		font-size: 12px;
		line-height: 12px;
		color: #221714;
	}

	.item-text>div {
		margin-bottom: 22px;
	}

	.min_item-text>div {
		margin-bottom: 12px;
	}

	.item-text>div:last-child {
		margin-bottom: 0;
	}

	.min_item-text>div:last-child {
		margin-bottom: 0;
	}

	.left-item-right {
		width: 119px;
		height: 119px;
	}

	.min_left-item-right {
		width: 100px;
		height: 100px;
	}

	.text {
		width: 100%;
		height: 89px;
		background: #4A3D3A;
		font-size: 10px;
		font-weight: 500;
		line-height: 15px;
		text-align: center;
		letter-spacing: 0em;
		color: #FFFFFF;
		display: flex;
		flex-direction: column;
		justify-content: center;
	}

	.text a,
	.min_text a {
		color: #FFFFFF;
	}

	.min_text {
		width: 100%;
		padding: 30px 45px;
		background: #4A3D3A;
		box-sizing: border-box;
		color: #FFFFFF;
		font-size: 10px;
		font-weight: 500;
		line-height: 15px;
		text-align: center;
	}

	.line {
		width: 1px;
		height: 297px;
		background-color: #333333;
	}

	.right {
		width: 980px;
		display: flex;
		justify-content: space-between;
		flex-shrink: 0;
	}

	.followus {
		display: flex;
		flex-direction: column;
		align-items: center;
	}

	.min_followus {
		display: flex;
		justify-content: center;
		padding: 40px 0;
		background: #F7F5F5;
	}

	.followus-item {
		width: 50px;
		height: 50px;
		margin-bottom: 34px;
		cursor: pointer;
		position: relative;
		z-index: 6;
	}

	.min_followus-item {
		width: 36px;
		height: 36px;
		margin-right: 40px;
		position: relative;
		z-index: 6;
	}

	.min_followus_pop {
		position: absolute;
		bottom: 45px;
		left: 50%;
		transform: translateX(-50%);
		width: 150px;
		height: 150px;
		padding: 10px;
		background: #E8E3DD;
		border-radius: 10px;
	}

	.min_followus-item>a {
		cursor: default;
	}

	.min_followus-item:last-child {
		margin-right: 0;
	}

	.followus-item-hover {
		position: absolute;
		width: 200px;
		height: 200px;
		top: 0;
		left: 60px;
		display: none;
	}

	.followus-item:hover>.followus-item-hover {
		display: block;
	}

	.followus-item:last-child {
		margin-bottom: 0;
	}

	.productscase {
		width: 230px;
		display: flex;
		flex-wrap: wrap;
	}

	.min_productscase {
		width: 100%;
		padding-top: 20px;
		max-height: 200px;
		overflow-x: scroll;
	}

	.productscase-item {
		width: 96px;
		font-size: 16px;
		font-weight: normal;
		line-height: 16px;
		letter-spacing: 0em;
		color: #221714;
		margin-right: 30px;
		margin-bottom: 32px;
		position: relative;
		cursor: pointer;
	}

	.productscase-item-pop {
		position: absolute;
		right: 100px;
		top: 0;
		background: #fff;
		width: 210px;
		padding: 10px;
		border-radius: 10px;
		font-size: 14px;
		line-height: 20px;
		color: #333;
		opacity: 0;
		transition: all 0.3s;
	}

	.productscase-item:hover .productscase-item-pop {
		opacity: 1;
	}

	.productscase-item-pop-download {
		margin-top: 10px;
		color: #C49D62;
		font-size: 14px;
		line-height: 20px;
		text-align: right;
	}

	.productscase-item-pop-download>img {
		width: 14px;
		height: auto;
	}

	.min_productscase-item {
		width: 100%;
		font-size: 14px;
		line-height: 14px;
		color: #333333;
		margin-bottom: 20px;
		position: relative;
	}

	.min_productscase-item-pop {
		position: absolute;
		top: 100%;
		left: 10%;
		/* transform: translateX(-50%); */
		background: #4a4745;
		padding: 15px 15px 10px;
		z-index: 6;
		border-radius: 5px;
		width: 180px;
		font-size: 12px;
		line-height: 16px;
		color: #fff;
	}

	.min-close {
		position: absolute;
		top: 5px;
		right: 5px;
	}

	.min-close>img {
		width: 20px;
		height: auto;
	}

	.min_productscase-item-pop-download {
		margin-top: 10px;
		color: #C49D62;
		font-size: 12px;
		line-height: 16px;
		text-align: right;
	}

	.min_productscase-item-pop-download>img {
		width: 12px;
		height: auto;
	}

	.min_productscase-item:last-child {
		margin-bottom: 0;
	}

	.enproductscase-item {
		width: 96px;
		font-size: 14px;
		margin-bottom: 20px;
		margin-right: 30px;
	}

	.productscase-item:nth-child(2n) {
		margin-right: 0;
	}

	.productscase-item:last-child {
		margin-bottom: 0;
	}

	.productscase-item:nth-last-child(2) {
		margin-bottom: 0;
	}

	.aboutus {
		display: flex;
		flex-direction: column;
		align-items: center;
	}

	.min_aboutus {
		width: 100%;
		padding-top: 20px;
	}

	.aboutus-item {
		font-size: 16px;
		font-weight: normal;
		line-height: 16px;
		letter-spacing: 0em;
		color: #221714;
		margin-bottom: 32px;
	}

	.min_aboutus-item {
		width: 100%;
		font-size: 14px;
		line-height: 14px;
		color: #333333;
		margin-bottom: 20px;
	}

	.min_aboutus-item:last-child {
		margin-bottom: 0;
	}

	.aboutus-item:last-child {
		margin-bottom: 0;
	}

	.link {
		display: flex;
		flex-direction: column;
		align-items: center;
	}

	.min_link {
		width: 100%;
		padding-top: 20px;
	}

	.link-item {
		font-size: 16px;
		font-weight: normal;
		line-height: 16px;
		letter-spacing: 0em;
		margin-bottom: 48px;
	}

	.link-item>a {
		color: #221714;
	}

	.min_link-item {
		width: 100%;
		font-size: 14px;
		line-height: 14px;
		margin-bottom: 20px;
	}

	.min_link-item>a {
		color: #333333;
	}

	.min_link-item:last-child {
		margin-bottom: 0;
	}


	.link-item:last-child {
		margin-bottom: 0;
	}
</style>