import {
	createRouter,
	createWebHashHistory
} from 'vue-router'
import Home from '@/views/Home.vue'

const routes = [{
		path: '/',
		name: 'Home',
		meta: {
			keepAlive: true //设置页面是否需要使用缓存
		},
		component: Home

	},
	{
		path: '/CultureBrand',
		name: 'CultureBrand',
		meta: {
			keepAlive: false //设置页面是否需要使用缓存
		},
		component: () => import( /* webpackChunkName: "about" */ '../views/CultureBrand.vue')
	},
	{
		path: '/MinHome',
		name: 'MinHome', 
		meta: {
			keepAlive: true //设置页面是否需要使用缓存
		},
		component: () => import( /* webpackChunkName: "about" */ '../views/MinHome.vue')
	},
	{
		path: '/MinCultureBrand',
		name: 'MinCultureBrand',
		meta: {
			keepAlive: false //设置页面是否需要使用缓存
		},
		component: () => import( /* webpackChunkName: "about" */ '../views/MinCultureBrand.vue')
	}
]

const router = createRouter({
	history: createWebHashHistory(),
	routes,
	scrollBehavior(to, from, savedPosition) {
		return {
			x: 0,
			y: 0
		}
	}
})
 

export default router