<template>
	<div>
		<div :class="ismobile ? 'min_content':'content'">
			<div :class="ismobile ? 'min_title':'title'" v-show="enchange">{{title}}</div>
			<div :class="ismobile ? 'min_title':'title'" v-show="!enchange">{{entitle}}</div>
			<div :class="ismobile ? 'min_news':'news'">
				<div class="min_tabbtn" v-if="ismobile">
					<div class="min_tabbtn-item" v-for="(item,index) in categoriesdata" :key="index"
						:class="{'min_tabbtn-item-active': tabindex == index}"
						@click="cateclick(item.id,index,item.CNname)">
						<span>
							<span v-show="enchange">{{item.CNname}}</span>
							<span v-show="!enchange">{{item.ENname}}</span>
						</span>
					</div>
				</div>

				<div class="tabbtn" v-else>
					<div class="tabbtn-item" v-for="(item,index) in categoriesdata" :key="index"
						:class="{'tabbtn-item-active': tabindex == index}"
						@click="cateclick(item.id,index,item.CNname)">
						<span>
							<span v-show="enchange">{{item.CNname}}</span>
							<span v-show="!enchange">{{item.ENname}}</span>
						</span>
						<div class="right_h" v-if="tabindex == index"><img src="../assets/imgs/h_right.png" alt="">
						</div>
					</div>
				</div>
				<div :class="ismobile ? 'min_news-item': 'news-item'">
					<ul class="animated" :class="ismobile ? 'min_news-list': 'news-list'" v-loading="loading">
						<li :class="ismobile ? 'min_news-list-item': 'news-list-item'" v-for="(item,index) in postdata"
							:key="index" v-show="changeshow">
							<a :href="item.excerpt.rendered" target="_blank">
								<div :class="ismobile ? 'min_news-img': 'news-img'">
									<img :src="item.imgs" alt="">
								</div>
								<div :class="ismobile ? 'min_news-text': 'news-text'">
									<div :class="ismobile ? 'min_news-title': 'news-title'" v-show="enchange">
										{{item.CNtitle}}
									</div>
									<div :class="ismobile ? 'min_news-title': 'news-title'" v-show="!enchange">
										{{item.ENtitle}}
									</div>
									<div :class="ismobile ? 'min_news-time': 'news-time'">{{item.date}}</div>
								</div>
							</a>
						</li>

						<li :class="ismobile ? 'min_news-list-item': 'news-list-item'"
							v-for="(item,index) in culturaldata" :key="index" v-show="!changeshow"
							@click="goCultureBrand">
							<div :class="ismobile ? 'min_news-img': 'news-img'">
								<img :src="item.link" alt="">
							</div>
							<div :class="ismobile ? 'min_news-text': 'news-text'">
								<div :class="ismobile ? 'min_news-title': 'news-title'" v-show="enchange">
									{{item.CNtitle}}
								</div>
								<div :class="ismobile ? 'min_news-title': 'news-title'" v-show="!enchange">
									{{item.ENtitle}}
								</div>
								<div :class="ismobile ? 'min_news-time': 'news-time'">{{item.date}}</div>
							</div>
						</li>
					</ul>
					<div :class="ismobile ? 'min_changebtn': 'changebtn'">
						<el-pagination :page-size="pagesize" :pager-count="7" layout="prev, pager, next"
							prev-icon="Back" next-icon="Right" :total="Number(totals)"
							v-model:current-page="currentPage" @current-change="pagechange" />
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import api from "../utils/api.js"
	export default {
		props: {
			ismobile: {
				type: Boolean,
				default: false
			},
			topi: {
				type: Number,
				default: null
			},
			enchange: {
				type: Boolean,
				default: true
			}
		},
		data() {
			return {
				title: "公司动态",
				entitle: "Company Dynamics",
				categoriesdata: [], //所有分类
				fristid: null,
				postdata: [], //文章
				culturaldata: [],
				pages: 1, //当前页数
				pagesize: 3,
				totals: null,
				currentPage: 1,
				istotals: true,
				apistatus: true,
				loading: false,
				tabindex: 0,
				changename: "公司新闻",
				changeshow: true
			}
		},
		watch: {
			topi(newtopi, oldtopi) {
				if(!this.ismobile) {
					if (newtopi == 3) {
						document.querySelector(".news-list").classList.add("fadeInLeft");
					} else {
						document.querySelector(".news-list").classList.remove("fadeInLeft");
					}
				} 
			}
		},
		mounted() {
			this.getdata();
		},
		methods: {
			// 获取所有分类
			getdata() {
				api.getcategories({
					per_page: 20
				}).then(res => {
					if (res.status == 200) {
						res.data.forEach(item => {
							if (item.name != "未分类") {
								item.name = item.name.split("—");
								item.CNname = item.name[0];
								item.ENname = item.name[1];
								this.categoriesdata.push(item);
							}
						})
						this.fristid = this.categoriesdata[0].id;
						this.getpost(this.fristid);
					}
				})
			},
			// 文章请求
			getpost(id) {
				if (this.ismobile) {
					this.pagesize = 2;
				} else {
					this.pagesize = 3;
				}

				this.postdata = [];
				this.culturaldata = [];
				let data = {
					categories: id,
					page: this.pages,
					per_page: this.pagesize
				}
				this.loading = true;
				api.getposts(data).then(res => {
					if (this.istotals) {
						this.totals = Number(res.headers['x-wp-total']);
						this.istotals = false;
					}
					if (this.tabindex == 0) {
						this.changeshow = true;
						this.dataeach(res.data);
					} else if (this.tabindex == 1) {
						this.changeshow = false;
						this.culturals(res.data);
					}
				})
			},
			async dataeach(data) {
				for (let item of data) {
					await this.getimg(item);
				}
				this.loading = false;
			},
			// 媒体图片请求
			async getimg(item) {
				await api.getmedia(item.featured_media).then(res => {
					if (res.status == 200) {
						item.imgs = res.data.source_url;
						item.date = item.date.substr(0, 10);
						item.excerpt.rendered = item.excerpt.rendered.replace("<p>", "");
						item.excerpt.rendered = item.excerpt.rendered.replace("</p>\n", "");
						item.title.rendered = item.title.rendered.split("###");
						item.CNtitle = item.title.rendered[0];
						item.ENtitle = item.title.rendered[1];
						this.postdata.push(item);
					}
				})
			},
			culturals(data_) {
				let data = data_[0];
				data.title.rendered = data.title.rendered.split("###");
				data.CNtitle = data.title.rendered[0];
				data.ENtitle = data.title.rendered[1];
				data.date = data.date.substr(0, 10);
				data.link = "";
				data.link = data.content.rendered.match(/src="(\S*)" alt=""/)[1];
				this.culturaldata.push(data);
				this.loading = false;
			},
			pagechange(number) {
				this.pages = number;
				this.getpost(this.fristid);
			},
			// 获取不同分类下的文章
			cateclick(id, index, name) {
				this.tabindex = index;
				this.istotals = true;
				this.pages = 1;
				this.changename = name;
				this.fristid = id;
				this.getpost(this.fristid);
			},
			goCultureBrand() {
				if(this.ismobile) {
					this.$router.push('/MinCultureBrand');
				} else {
					this.$router.push('/CultureBrand');
				}
			}
		}
	}
</script>

<style scoped>
	.content {
		width: 100%;
		height: 100vh;
		display: flex;
		flex-direction: column;
		justify-content: center;
		/* padding-left: 135px; */
		box-sizing: border-box;
		z-index: 3;
		background: #fff;
	}

	.min_content {
		width: 100%;
		height: auto;
		min-height: 100vh;
		padding: 0 20px;
		box-sizing: border-box;
		display: flex;
		flex-direction: column;
		justify-content: center;
	}

	.title {
		padding-left: 135px;
		padding-bottom: 100px;
		font-size: 34px;
		font-weight: normal;
		line-height: 24px;
		letter-spacing: 0em;
		color: #333333;
	}

	.min_title {
		font-size: 26px;
		font-weight: normal;
		line-height: 24px;
		letter-spacing: 0em;
		color: #333333;
		padding-top: 40px;
	}

	.news {
		display: flex;
		justify-content: space-between;
	}

	.min_news {
		display: flex;
		flex-direction: column;
	}

	.tabbtn {
		background: #fff;
		padding-left: 135px;
		padding-right: 60px;
		z-index: 9;
	}

	.min_tabbtn {
		display: flex;
		margin-top: 42px;
		margin-bottom: 32px;
	}

	.tabbtn-item {
		width: 164px;
		height: 68px;
		line-height: 68px;
		font-size: 18px;
		font-weight: normal;
		letter-spacing: 0em;
		color: #333333;
		cursor: pointer;
		border-bottom: 1px solid #b0b0b0;
		display: flex;
		justify-content: space-between;
		align-items: center;
	}

	.min_tabbtn-item {
		width: 50%;
		text-align: center;
	}

	.tabbtn-item .right_h {
		width: 20px;
	}

	.tabbtn-item:first-child {
		border-top: 3px solid #C49D62;
	}

	.tabbtn-item-active {
		font-size: 22px;
		color: #C49D62;
	}

	.min_tabbtn-item-active {
		position: relative;
		color: #C49D62;
	}

	.min_tabbtn-item-active::before {
		content: '';
		display: block;
		position: absolute;
		bottom: -8px;
		left: 50%;
		transform: translateX(-50%);
		width: 28px;
		height: 3px;
		border-radius: 57px;
		background: #E3A95F;
	}

	.news-item {
		padding-right: 75px;
	}

	.min_news-item {
		padding-bottom: 40px;
	}

	.news-list {
		width: 1500px;
		min-height: 400px;
		display: flex;
		margin-bottom: 76px;
	}

	.min_news-list {
		width: 100%;
		display: flex;
		flex-direction: column;
		margin-bottom: 52px;
		min-height: 500px;
	}

	.news-list-item {
		margin: 0 20px;
		width: 460px;
		cursor: pointer;
	}

	.min_news-list-item {
		width: 100%;
		margin-bottom: 12px;
	}

	.min_news-list-item:last-child {
		margin-bottom: 0;
	}

	.news-img {
		width: 460px;
		height: 227px;
	}

	.min_news-img {
		width: 100%;
		height: 165px;
		border-radius: 10px;
		overflow: hidden;
	}

	.news-text {
		height: 174px;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
	}

	.min_news-text {
		height: auto;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
	}

	.news-title {
		font-size: 18px;
		font-weight: 250;
		line-height: 41px;
		letter-spacing: 0em;
		color: #221714;
		margin-top: 47px;
		display: -webkit-box;
		-webkit-box-orient: vertical;
		-webkit-line-clamp: 2;
		overflow: hidden;
		text-overflow: ellipsis;
	}

	.min_news-title {
		font-size: 16px;
		font-weight: normal;
		line-height: 16px;
		letter-spacing: 0em;
		color: #221714;
		display: -webkit-box;
		-webkit-box-orient: vertical;
		-webkit-line-clamp: 2;
		overflow: hidden;
		text-overflow: ellipsis;
		margin-top: 24px;
		margin-bottom: 15px;
	}

	.news-time {
		font-size: 14px;
		font-weight: normal;
		line-height: 14px;
		letter-spacing: 0em;
		color: #999999;
	}

	.min_news-time {
		font-size: 12px;
		font-weight: normal;
		line-height: 12px;
		letter-spacing: 0em;
		color: #999999;
	}

	.changebtn {
		display: flex;
	}

	.min_changebtn {
		display: flex;
		justify-content: center;
	}

	.changebtn ::v-deep(.el-pager li) {
		font-size: 24px;
		padding: 0 20px;
	}

	.min_changebtn ::v-deep(.el-pager li) {
		font-size: 14px;
		cursor: auto;
	}

	.changebtn ::v-deep(.el-pager li.is-active) {
		color: #C49D62;
	}

	.min_changebtn ::v-deep(.el-pager li.is-active) {
		color: #C49D62;
	}

	.changebtn ::v-deep(.el-pager li.number:hover) {
		color: #C49D62;
	}

	.min_changebtn ::v-deep(.el-pager li.number:hover) {
		color: #C49D62;
	}

	.changebtn ::v-deep(.el-pagination .btn-next .el-icon),
	.changebtn ::v-deep(.el-pagination .btn-prev .el-icon) {
		font-size: 40px;
		font-weight: 1000;
	}

	.min_changebtn::v-deep(.el-pagination .btn-next .el-icon),
	.min_changebtn::v-deep(.el-pagination .btn-prev .el-icon) {
		font-size: 20px;
		font-weight: 1000;
		cursor: auto;
	}

	.changebtn ::v-deep(.el-pagination button:hover),
	.changebtn ::v-deep(.el-pagination button:hover) {
		color: #C49D62;
	}
	.min_changebtn ::v-deep(.el-pagination button) {
		cursor: auto;
	}
	
	.min_changebtn ::v-deep(.el-pagination button:hover),
	.min_changebtn ::v-deep(.el-pagination button:hover) {
		color: #C49D62;
	}

	.changebtn-item {
		width: 60px;
		height: 60px;
		display: flex;
		justify-content: center;
		align-items: center;
		margin-right: 56px;
	}

	.changebtn-item:last-child {
		margin-right: 0;
	}

	.changebtn-list {
		width: 15px;
		height: 14px;
	}
</style>