<template>
	<div :id="contname" :style="`width: ${widthheight}px;height: ${widthheight}px;`"></div>
</template>

<script>
	import * as THREE from "three"; //引入Threejs 
	import {
		OrbitControls
	} from 'three/examples/jsm/controls/OrbitControls'
	import {
		GLTFLoader
	} from "three/examples/jsm/loaders/GLTFLoader.js";
	import {
		FBXLoader
	} from "three/examples/jsm/loaders/FBXLoader";

	import {
		OBJLoader
	} from 'three/examples/jsm/loaders/OBJLoader'


	import {
		MTLLoader
	} from 'three/examples/jsm/loaders/MTLLoader'
	let scene = null;
	let mesh = null;
	export default {
		// name: 'ImportTemplate',
		props: {
			murl: {
				type: String,
				default: null
			},
			widthheight: {
				type: Number,
				default: 200
			},
			contname: {
				type: String,
				default: "container"
			}
		},
		data() {
			return {
				// scene: null,
				light: null,
				camera: null,
				renderer: null,
				// mesh: null,
				group: null,
				clock: null,
				FPS: 30,
				singleFrameTime: null,
				timeStamp: 0,
			}
		},
		mounted() {
			this.init()
			this.loadGltf()
		},

		methods: {
			init() {
				let container = null;
				container = document.getElementById(this.contname); //显示3D模型的容器 
				if (scene != null) {
					// this.clearThree(scene); 
					while (scene.children.length > 0) {
						scene.remove(scene.children[0]);
					}
					scene.clear();
				}
				// scene.clear();
				scene = new THREE.Scene();
				scene.add(new THREE.AmbientLight(0x404040, 1)); //环境光
				this.light = new THREE.DirectionalLight(0xffffff, 1); //从正上方（不是位置）照射过来的平行光，0.45的强度
				this.light.position.set(20, 20, 20);
				this.light.position.multiplyScalar(0.3);
				this.light.shadow.camera.near = 0.01;
				this.light.shadow.camera.far = 60;
				this.light.shadow.camera.top = 22;
				this.light.shadow.camera.bottom = -22;
				this.light.shadow.camera.left = -35;
				this.light.shadow.camera.right = 35; // //设置阴影分辨率
				this.light.shadow.mapSize.width = 2048; // default
				this.light.shadow.mapSize.height = 2048; // default//阴影限制
				this.light.shadow.radius = 1;
				scene.add(this.light);
				this.clock = new THREE.Clock(); //计时器
				this.singleFrameTime = (1 / this.FPS);

				/**
				 * 相机设置
				 */

				this.camera = new THREE.PerspectiveCamera(
					70,
					container.clientWidth / container.clientHeight,
					0.01,
					10
				);
				this.camera.position.z = 1;
				/**
				 * 创建渲染器对象
				 */
				this.renderer = new THREE.WebGLRenderer({
					alpha: true
				});
				this.renderer.toneMappingExposure = 0;
				this.renderer.setSize(container.clientWidth, container.clientHeight);
				container.appendChild(this.renderer.domElement);
				// let orbitControls = new OrbitControls(this.camera, this.renderer.domElement);

			},
			loadGltf() {
				let self = this;
				let loader = new GLTFLoader();
				//本地模型路径：public/static/mod/Xbot.glb
				loader.load(this.murl, function(gltf) {
					self.isLoading = false; //关闭载入中效果
					mesh = gltf.scene;

					mesh.traverse(function(child) {
						if (child.isMesh) {
							child.material.emissive = child.material.color;
							child.material.emissiveMap = child.material.map;
						}
					});

					mesh.scale.set(0.12, 0.12, 0.12); //设置大小比例
					mesh.position.set(0, 0, 0); //设置位置
					scene.add(mesh); // 将模型引入three
					self.animate();
				});


			},
			animate() {
				if (mesh) {
					requestAnimationFrame(this.animate);
					const delta = this.clock.getDelta(); //获取距离上次请求渲染的时间
					this.timeStamp += delta;
					if (this.timeStamp > this.singleFrameTime) {
						mesh.rotation.x += 0.01;
						mesh.rotation.y += 0.01; //绕Y轴旋转0.004°
						this.renderer.render(scene, this.camera);
						// 剩余的时间合并进入下次的判断计算 这里使用取余数是因为 当页页面失去焦点又重新获得焦点的时候，delta数值会非常大， 这个时候就需要
						this.timeStamp = (this.timeStamp % this.singleFrameTime);
					}
				}
			},
			clearThree(obj) {
				while (obj.children.length > 0) {
					// clearThree(obj.children[0])
					obj.remove(obj.children[0]);
				}
				if (obj.geometry) obj.geometry.dispose()
				if (obj.material) {
					//in case of map, bumpMap, normalMap, envMap ...
					Object.keys(obj.material).forEach(prop => {
						if (!obj.material[prop])
							return
						if (typeof obj.material[prop].dispose === 'function')
							obj.material[prop].dispose()
					})
					obj.material.dispose()
				}
			},
		}
	}
</script>

<style scoped>

</style>