<template>
	<div class="container" @mousewheel="mouseWheelHandle" @DOMMouseScroll="mouseWheelHandle">
		<!-- 导航栏 -->
		<NavBar :enchange="enchange" :writeshow="writeshow" :circleindex="circleindex" :navlist="navlist"
			:ennavlist="ennavlist" @circlebtn="circlebtn" @enchangebtn="enchangebtn" :color='"var(--i-color)"'
			:background='"var(--i-navbackcolor)"'></NavBar>
		<!-- 导航栏结束 -->

		<!-- 首页 -->
		<div class="jumpbtn contbox" style="height: 500vh;">
			<div class="content">
				<div class="videocont">
					<img src="../assets/imgs/bg.png" alt="">
				</div>
			</div>
			<!-- logo变换 -->
			<LogoChange :enchange="enchange" :textshow="textshow"></LogoChange>
			<!-- logo结束 -->
		</div>
		<!-- 首页结束 -->

		<div class="circlepop" v-show="popshow">
			<div class="circlepop-item"></div>
		</div>

		<div class="pagelist">
			<!-- @mousewheel="mouseWheelHandle" @DOMMouseScroll="mouseWheelHandle" -->
			<!-- <div style="height: auto;transform: translateY();"> -->
			<!-- 公司介绍 -->
			<CompanyProfile class="jumpbtn pagemate" :enchange="enchange"></CompanyProfile>
			<!-- 公司介绍结束 -->

			<!-- 产品案例 -->
			<ProductsCase class="jumpbtn pagemate" :topi="topi" :enchange="enchange"></ProductsCase>
			<!-- 产品案例结束 -->

			<!-- 核心业务 -->
			<Business class="jumpbtn pagemate" :enchange="enchange" :i="i" @enterimg="enterimg" @leaveimg="leaveimg"></Business>
			<!-- 核心业务结束 -->

			<!-- 公司动态 -->
			<CompanyDynamics class="jumpbtn pagemate" :topi="topi" :enchange="enchange">
			</CompanyDynamics>
			<!-- 公司动态结束 -->

			<!-- 联系我们 -->
			<ContactUs class="jumpbtn pagemate" :enchange="enchange"></ContactUs>
			<!-- 联系我们结束 -->
			<!-- </div> -->
		</div>


		<!-- 侧边导航栏 -->
		<ul class="sidenav">
			<li class="sidenav-item" :class="{'sidenav-active': circleindex == index}" v-for="(item,index) in navlist"
				:key="index" @click="circlebtn(index)">
				<div class="circle"></div>
			</li>
		</ul>
		<!-- 侧边导航栏结束 -->
	</div>
</template>

<script>
	import NavBar from "@/components/NavBar.vue"
	import LogoChange from "@/components/LogoChange.vue"
	import CompanyProfile from "@/components/CompanyProfile.vue"
	import ProductsCase from "@/components/ProductsCase.vue"
	import Business from "@/components/Business.vue"
	import CompanyDynamics from "@/components/CompanyDynamics.vue"
	import ContactUs from "@/components/ContactUs.vue" 
	export default {
		components: {
			NavBar,
			LogoChange,
			CompanyProfile,
			ProductsCase,
			Business,
			CompanyDynamics,
			ContactUs
		},
		data() {
			return {
				tiemr: null, // 计时器
				mintiemr: null,
				navlist: ["首页", "公司介绍", "产品案例", "核心业务", "公司动态", "关于我们"],
				ennavlist: ["Home", "Corporate Profile", "Product Case", "Core Businesses", "Company Dynamics", "About"],
				circleindex: 0,
				windowWidth: null, // 可视屏幕宽度
				clientheight: null, // 可视屏幕高度
				circlewidth: null, // 遮罩圆圈的直径
				scrollTop: null, // 页面滚动高度
				popshow: true,
				html: null,
				toph: 0,
				topi: -1,
				isScrolling: false,
				deltaY: null,
				writeshow: true, //图标黑白转换
				enchange: true,
				// current: 1,
				textshow: false,
				img: null,
				i: -1,
				tiemr: null, // 计时器 
			}
		},
		mounted() { 
			this.init();
		},
		onUnmounted() {
			clearInterval(this.tiemr);
			window.removeEventListener('scroll', this.scrollHandle); 
		},
		beforeRouteLeave() { 
			clearInterval(this.tiemr);
			window.removeEventListener('scroll', this.scrollHandle); 
		},
		watch: {
			windowWidth(val) { //监听屏幕宽度
				this.windowWidth = val;
			},
			clientheight(val) { //监听屏幕高度
				this.clientheight = val;
			},
		},
		activated() { 
			if (this.scrollTop > 0) {
				window.scrollTo(0, this.scrollTop);
				// this.scrollTop = 0;
			}
			window.addEventListener('scroll', this.scrollHandle); //绑定页面滚动事件
			 
			this.img = document.querySelectorAll(".img_");
			this.tiemr = setInterval(() => {
				this.settimer();
			}, 5000);
			setTimeout(() => {
				if (this.$route.query.circleindex != undefined || this.$route.query.circleindex != null) {
					this.circleindex = Number(this.$route.query.circleindex);
					this.changepages(this.circleindex);
				}
			}, 500)
		},
		methods: {
			init() {
				window.addEventListener('scroll', this.scrollHandle); //绑定页面滚动事件
				this.windowWidth = document.documentElement.clientWidth; //获取屏幕可视宽度
				this.clientheight = document.documentElement.clientHeight; //获取屏幕可视高度
				window.onresize = () => { //监听window窗口变化，当窗口大小发生变化时，会触发此事件  获取屏幕可视宽度,高度
					return (() => {
						this.windowWidth = document.documentElement.clientWidth;
						this.clientheight = document.documentElement.clientHeight;
					})()
				}; 
				// window.addEventListener('mousewheel', this.mouseWheelHandle);
				// window.addEventListener('DOMMouseScroll', this.mouseWheelHandle);
				this.circlewidth = Math.sqrt((this.windowWidth * this.windowWidth) + (this.clientheight * this
					.clientheight));

				this.html = document.querySelector(":root");
				if (this.clientheight > 0 && this.html) {
					this.html.style.setProperty("--i-width", `${this.circlewidth}px`);
				}
				this.html.style.setProperty("--i-pagetop", 0); 
			},
			scrollHandle(e) {
				this.scrollTop = e.srcElement.scrollingElement.scrollTop; // 获取页面滚动高度 
				// console.log(this.scrollTop)
				let h = null;
				let x = null;
				x = this.scrollTop / this.clientheight;
				h = this.circlewidth * (1 - x);
				if (h > 75 && this.html) {
					this.html.style.setProperty("--i-width", `${h}px`);
					this.html.style.setProperty("--i-background", "transparent");
				} else {
					this.html.style.setProperty("--i-background", "#C49D62");
					this.html.style.setProperty("--i-width", "75px");
				}
				if (this.scrollTop >= this.clientheight) {
					this.writeshow = false;
					this.html.style.setProperty("--i-color", "#000");
					this.html.style.setProperty("--i-navbackcolor", "#fff");
					this.html.style.setProperty("--i-borderbottom", "transparent");
				} else {
					this.writeshow = true;
					this.html.style.setProperty("--i-color", "#fff");
					this.html.style.setProperty("--i-navbackcolor", "transparent");
					this.html.style.setProperty("--i-borderbottom", "#333333");
				}
				if (this.scrollTop >= this.clientheight * 2) {
					if (this.html) {
						this.html.style.setProperty("--i-opacity", 1);
						this.popshow = false;
						// let left_ = null;
						// left_ = 75 * ((this.scrollTop - (this.clientheight * 2)) / (this.clientheight * 0.3));
						// if (left_ <= 75) {
						this.html.style.setProperty("--i-left", `${-75}px`);
						// }
					}
				} else {
					this.popshow = true;
					this.html.style.setProperty("--i-opacity", 0);
					this.html.style.setProperty("--i-left", `0px`);
				}

				if (this.scrollTop >= this.clientheight * 2.3) {
					this.html.style.setProperty("--i-opacity", 0);
					this.html.style.setProperty("--i-logo", 1);
				} else {
					this.html.style.setProperty("--i-logo", 0);
					this.html.style.setProperty("--i-opacity", 1);
				}

				if (this.scrollTop >= this.clientheight * 2.5) {
					this.html.style.setProperty("--i-logotop", `${-75}px`);
					this.textshow = true;
				} else {
					this.html.style.setProperty("--i-logotop", "0");
					this.textshow = false;
				}
				if (this.scrollTop >= this.clientheight * 2.7) {
					this.html.style.setProperty("--i-textopacity", 1);
				} else {
					this.html.style.setProperty("--i-textopacity", 0);
				}

				if (this.scrollTop >= this.clientheight * 3) {
					this.html.style.setProperty("--i-logoscale", 0.8);
				} else {
					this.html.style.setProperty("--i-logoscale", 1);
				}
				this.pagemate();
			},
			pagemate() {
				if (this.scrollTop < this.clientheight * 4.5) {
					this.circleindex = 0;
				} else {
					this.indexchange();
				}
				if (this.scrollTop >= 5 * this.clientheight && this.topi < 1) {
					this.circleindex = 1;
					this.html.style.setProperty("--i-navbackcolor", "transparent");
					this.html.style.setProperty("--i-borderbottom", "#cccccc");
				} else if (this.scrollTop >= this.clientheight) {
					this.html.style.setProperty("--i-borderbottom", "transparent");
				}
				if (this.topi == 2) {
					this.html.style.setProperty("--i-navbackcolor", "transparent");
				}
			},
			mouseWheelHandle(event) { 
				if (this.scrollTop >= this.clientheight * 5) {  
					let evt = event || window.event;
					if (evt.stopPropagation) {
						evt.stopPropagation();
					} else {
						evt.returnValue = false;
					}
					if (this.isScrolling) { // 判断是否可以滚动
						return false;
					}
					let e = event.originalEvent || event;
					this.deltaY = e.deltaY || e.detail; // Firefox使用detail
					if (this.deltaY > 0) {
						this.next();
					} else if (this.deltaY < 0) {
						this.pre();
					}
				}
			},

			next() { // 往下切换
				if (this.topi < 4) {
					this.topi = this.topi + 1;
					this.indexchange();
					this.move(this.topi);
				}
			},
			pre() { // 往上切换 
				if (this.topi > 0) {
					this.topi = this.topi - 1;
					this.indexchange();
					this.move(this.topi);
				}
			},
			indexchange() {
				if (this.topi == 1) {
					this.circleindex = 2;
				}
				if (this.topi == 2) {
					this.circleindex = 3;
				}
				if (this.topi == 3) {
					this.circleindex = 4;
				}
				if (this.topi == 4) {
					this.circleindex = 5;
				}
			},
			move(top) {
				this.isScrolling = true; // 为了防止滚动多页，需要通过一个变量来控制是否滚动
				this.html.style.setProperty("--i-pagetop", `${-(top*100)}vh`);
				setTimeout(() => { //这里的动画是1s执行完，使用setTimeout延迟1s后解锁
					this.isScrolling = false;
				}, 500);
			},

			circlebtn(index) {
				this.changepages(index);
			},
			changepages(index) {
				// this.circleindex = index;
				this.topichange(index);
				document.querySelectorAll(".jumpbtn")[index].scrollIntoView({
					behavior: "smooth",
					block: 'start'
				});
			},
			topichange(index) {
				if (index == 0 || index == 1) {
					this.topi = 0;
				}
				if (index == 2) {
					this.topi = 1;
				}
				if (index == 3) {
					this.topi = 2;
				}
				if (index == 4) {
					this.topi = 3;
				}
				if (index == 5) {
					this.topi = 4;
				}
				this.move(this.topi);
			},
			// 中英文切换
			enchangebtn() {
				this.enchange = !this.enchange;
			},
			settimer() {
				this.i++;
				if (this.i > this.img.length - 1) {
					this.i = 0;
				}
			},
			enterimg() {
				clearInterval(this.tiemr);
			},
			leaveimg() {
				this.tiemr = setInterval(() => {
					this.settimer();
				}, 5000);
			}
		}
	}
</script>

<style>
	:root {
		--i-width: 0;
		--i-color: #fff;
		/* --i-circle: 0; */
		--i-background: transparent;
		--i-left: 0px;
		--i-opacity: 0;
		--i-logo: 0;
		--i-logotop: 0;
		--i-logoscale: 0;
		--i-position: static;
		--i-pagetop: 0;
		--i-navbackcolor: transparent;
		--i-borderbottom: #333333;
		--i-textopacity: 0;
	}

	.container {
		height: 1000vh;
	}

	.circlepop {
		position: fixed;
		top: 0;
		width: 100%;
		height: 100vh;
		overflow: hidden;
		z-index: 4;
	}

	.circlepop-item {
		position: absolute;
		width: var(--i-width);
		height: var(--i-width);
		border-radius: 50%;
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);
		/*默认居中*/
		box-shadow: 0 0 0 250vw rgba(255, 255, 255);
		/*足够大的投影*/
		background: var(--i-background);

	}

	.content {
		width: 100%;
		height: 200vh;
		position: relative;
	}

	.videocont {
		width: 100%;
		height: 100vh;
		position: sticky;
		top: 0;
	}

	.sidenav {
		position: fixed;
		top: 50%;
		right: 46px;
		transform: translateY(-50%);
		z-index: 6;
	}

	.sidenav-item {
		width: 42px;
		height: 42px;
		border-radius: 50%;
		display: flex;
		justify-content: center;
		align-items: center;
		margin-bottom: 20px;
		box-sizing: border-box;
		cursor: pointer;
	}

	.sidenav-active {
		border: 1px solid #B6B6B6;
	}

	.sidenav-item:last-child {
		margin-bottom: 0;
	}

	.sidenav-item .circle {
		width: 5px;
		height: 5px;
		border-radius: 50%;
		background: #B6B6B6;
	}

	.pagelist {
		width: 100%;
		height: 100vh;
		/* overflow: hidden; */
		position: sticky;
		top: 0;
		transition: all 0.5s;
		transform: translate(0, var(--i-pagetop));
	}

	/* .pagelist>div {
		transition: all 0.5s;
		transform: translate(0, var(--i-pagetop));
	} */

	.pagemate {
		/* position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%; */
	}
</style>