<template>
	<div>
		<div :class="ismobile ? 'min_content':'content'">
			<div :class="ismobile ? 'min_title': 'title'" v-show="enchange">{{title}}</div>
			<div :class="ismobile ? 'min_title': 'title'" v-show="!enchange">{{entitle}}</div>
			<!-- <img class="min_imgs" v-if="ismobile" src="../assets/imgs/min_hxbg.png" alt=""> -->
			<div class="imgscont">
				<div class="imgs_cont">
					<img class="min_imgs" v-if="ismobile" src="../assets/imgs/min_hxbg.png" alt="">
					<img class="imgs" v-else src="../assets/imgs/hxbg.png" alt="">
					<div :class="ismobile ? 'min_img': 'img'" @click="minimgclick">
						<div class="img_" v-for="(item,index) in moudeldata" :key="index"
							:class="ismobile ? `min_img_${index+1}`: `img_${index+1}`">
							<div :class="ismobile ? 'min_img-item': 'img-item'" @mouseenter.stop="enterimg(index)"
								@mouseleave.stop="leaveimg(index)">
								<div class="imgaction" @click.stop="clickimg(index)">
									<div class="imgaction_in">
										<div class="imgaction_item"></div>
									</div>
								</div>
								<div class="img-item-hover" v-if="!ismobile">
									<div class="hover-title" v-if="enchange">{{item.title}}</div>
									<div class="hover-title" v-if="!enchange">{{item.entitle}}</div>
									<div class="hover-tap" v-if="enchange">
										<span v-for="(itemt,indext) in item.model" :key="indext"
											@mouseenter="enterhover(indext)"
											:class="{'hover-tap-active': enterhoverindex == indext}">{{itemt.title}}</span>
									</div>
									<div class="hover-tap" v-if="!enchange">
										<span v-for="(itemt,indext) in item.model" :key="indext"
											@mouseenter="enterhover(indext)"
											:class="{'hover-tap-active': enterhoverindex == indext}">{{itemt.entitle}}</span>
									</div>
									<div class="hover-cont" v-if="enchange">
										<div v-for="(itemt,indext) in item.model" :key="indext">
											<div class="hover-cont-img" v-if="enterhoverindex == indext">
												<img :src="itemt.url" alt="">
											</div>
											<div class="hover-cont-text" v-if="enterhoverindex == indext">{{itemt.text}}
											</div>
										</div>
									</div>
									<div class="hover-cont" v-if="!enchange">
										<div v-for="(itemt,indext) in item.model" :key="indext">
											<div class="hover-cont-img" v-if="enterhoverindex == indext">
												<img :src="itemt.url" alt="">
											</div>
											<div class="hover-cont-text" v-if="enterhoverindex == indext">
												{{itemt.entext}}
											</div>
										</div>
									</div>
								</div>

								<div class="min_img-item-hover" v-show="index == min_i && minpopshow" @click.stop v-else>
									<!-- minpopshow && -->
									<div>
										<div class="min_hover-title" v-if="enchange">{{item.title}}</div>
										<div class="min_hover-title" v-if="!enchange">{{item.entitle}}</div>
										<div class="min_hover-tap" :class="item.model.length > 1 ? 'min_hover-tap_2': 'min_hover-tap_1'" v-if="enchange">
											<span v-for="(itemt,indext) in item.model" :key="indext"
												@click="enterhover(indext)"
												:class="{'hover-tap-active': enterhoverindex == indext}">{{itemt.title}}</span>
										</div>
										<div class="min_hover-tap" :class="item.model.length > 1 ? 'min_hover-tap_2': 'min_hover-tap_1'" v-if="!enchange">
											<span v-for="(itemt,indext) in item.model" :key="indext"
												@click="enterhover(indext)"
												:class="{'hover-tap-active': enterhoverindex == indext}">{{itemt.entitle}}</span>
										</div>
									</div>
									<div class="min_hover-cont" v-if="enchange">
										<div v-for="(itemt,indext) in item.model" :key="indext">
											<div class="min_hover-cont-img" v-if="enterhoverindex == indext">
												<img :src="itemt.url" alt="">
											</div>
											<div class="min_hover-cont-text" v-if="enterhoverindex == indext">
												{{itemt.text}}
											</div>
										</div>
									</div>
									<div class="min_hover-cont" v-if="!enchange">
										<div v-for="(itemt,indext) in item.model" :key="indext">
											<div class="min_hover-cont-img" v-if="enterhoverindex == indext">
												<img :src="itemt.url" alt="">
											</div>
											<div class="min_hover-cont-text" v-if="enterhoverindex == indext">
												{{itemt.entext}}
											</div>
										</div>
									</div>
								</div>


							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		props: {
			i: {
				type: Number,
				default: -1
			},
			ismobile: {
				type: Boolean,
				default: false
			},
			enchange: {
				type: Boolean,
				default: true
			}
		},
		data() {
			return {
				minpopshow: true,
				title: "核心业务",
				entitle: "Core Businesses",
				tiemr: null, // 计时器
				mintiemr: null,
				// i: -1,
				min_i: null,
				img: null,
				contactive: 0,
				enterhoverindex: 0, //进入的小模块 
				moudeldata: [{
					title: "舆情分析",
					entitle: "Opinion Analysis",
					model: [{
						title: "演艺舆情信息传播",
						entitle: "Performing Arts Public Opinion Information Dissemination",
						url: require("../assets/imgs/hxyw/yqfx_1.png"),
						text: "帮助演艺从业者了解公众的需求和反馈，指导演艺项目的策划和推广、帮助演艺从业者和相关机构及时发现和应对舆情危机，保护企业和品牌的声誉。",
						entext: "It helps performing arts practitioners understand public demand and feedback, guides the planning and promotion of performing arts projects, helps performing arts practitioners and related organizations identify and respond to public opinion crises in a timely manner, and protects the reputation of enterprises and brands."
					}]
				}, {
					title: "演出大数据",
					entitle: "Performance Big Data",
					model: [{
						title: "陕演大数据库",
						entitle: "Shannxi University Database",
						url: require("../assets/imgs/hxyw/ycdsj_1.png"),
						text: "陕西演艺大数据库建设及产业化项目是旨在建设陕西省演艺大数据库并推动其产业化发展的项目。该项目的目标是通过收集、整理和管理陕西省内的演艺资源，建立一个全面、准确、可靠的演艺数据库，为相关行业提供数据支持和服务。",
						entext: "The Shaanxi Performing Arts Database Construction and Industrialization Project is a project aimed at building a large database of performing arts in Shaanxi Province and promoting its industrialization. The goal of the project is to establish a comprehensive, accurate and reliable performing arts database by collecting, organizing and managing performing arts resources in Shaanxi Province, so as to provide data support and services for related industries."
					}, {
						title: "文旅多模态数据",
						entitle: "Multimodal data for cultural tourism",
						url: require("../assets/imgs/hxyw/ycdsj_2.png"),
						text: "文旅多模态数据处理是指对文化旅游领域中的多种数据进行整合、清洗、分析和挖掘，包括文字、图片、音频、视频等不同形式的数据。通过对这些数据进行处理，可以提取出有用的信息和知识，为后续的应用提供支持。",
						entext: "Multimodal data processing in culture and tourism refers to the integration, cleaning, analysis and mining of multiple data in the field of culture and tourism, including different forms of data such as text, pictures, audio and video. By processing these data, useful information and knowledge can be extracted to support subsequent applications."
					}]
				}, {
					title: "软件开发",
					entitle: "Software Development",
					model: [{
						title: "数字办公",
						entitle: "Digital Office",
						url: require("../assets/imgs/hxyw/rjkf_1.png"),
						text: "依托现代互联网技术，建立以OA系统为核心的数字办公集成平台，提升企业的管理的实效性、便捷性，同时降低管理成本，实现集约化管理，并依托数字办公集成平台最终实现集团日常运营管理、重大项目申报、客户资源管理等集团运营的数字化管理和办公。",
						entext: "Relying on modern Internet technology, the establishment of the OA system as the core of the digital office integration platform, to enhance the effectiveness and convenience of the enterprise's management, while reducing management costs, to achieve intensive management, and relying on the digital office integration platform to ultimately realize the group's daily operations management, major project declaration, customer resource management and other group operations of digital management and office."
					}, {
						title: "申报系统",
						entitle: "Filing system",
						url: require("../assets/imgs/hxyw/rjkf_2.png"),
						text: "申报系统可以帮助机构、企业或个人更高效地完成各种申报流程，包括报销、申请、审批、统计等，可以帮助用户更好地完成各种申报事务，提高工作效率和质量。无论是机构、企业还是个人，都可以通过申报系统实现更加便捷和精确的申报管理。",
						entext: "The declaration system can help organizations, enterprises or individuals to complete various declaration processes more efficiently, including reimbursement, application, approval, statistics, etc. It can help users to better complete various declaration affairs and improve work efficiency and quality. Whether it is an organization, enterprise or individual, the declaration system can realize more convenient and accurate declaration management."
					}]
				}, {
					title: "文创周边",
					entitle: "Cultural and Creative Periphery",
					model: [{
						title: "陕演MALL",
						entitle: "Shaanxi Performance MALL",
						url: require("../assets/imgs/hxyw/whzb.png"),
						text: "陕演MALL贸易平台是文化演艺衍生品的展览展示和线上交易平台，提供演艺衍生品相关产品信息查询、咨询、订购等服务，并在此基础上，逐渐扩大服务范围，面向全国进行文创产品的宣传和销售。",
						entext: "Shaanxi Performing Arts MALL Trade Platform is an exhibition and online trading platform for cultural performing arts derivatives, providing services such as information inquiry, consultation and ordering of products related to performing arts derivatives, and gradually expanding the scope of services to publicize and sell cultural and creative products nationwide."
					}]
				}, {
					title: "直播服务",
					entitle: "Live streaming service",
					model: [{
						title: "现场直播",
						entitle: "broadcast on the spot",
						url: require("../assets/imgs/hxyw/zbfw.png"),
						text: "无论是企业内部的会议直播、产品发布会、培训课程，还是各类线上活动、音乐演唱会、体育赛事等，我们都能够提供稳定、高清的直播服务。无论您是企业、机构、个人还是组织，选择我们的直播服务，您将获得专业、可靠的合作伙伴，共同打造成功的直播活动。",
						entext: "Whether it's an internal meeting live streaming, product launch, training course, or all kinds of online activities, music concerts, sports events, etc., we are able to provide stable, high-definition live streaming services. Whether you are an enterprise, institution, individual or organization, by choosing our live streaming service, you will get a professional and reliable partner to build a successful live event together."
					}]
				}, {
					title: "票务代理服务",
					entitle: "Ticket agency services",
					model: [{
						title: "票务中心",
						entitle: "Ticket Center",
						url: require("../assets/imgs/hxyw/pwzx.png"),
						text: "陕演票务以陕西为核心，致力形成陕西领先、西部一流、全国知名的综合类演出票务服务体系。我们具备在线选座购票、电子验票、支付保障等多项功能，为消费者提供便捷优质的购票服务，呈现更多元、更开放、更多彩的文艺演出活动。同时承接各大院团、主办方的演出项目推广与票务服务等业务。",
						entext: "With Shaanxi as its core, Shaanxi Performance Ticketing is committed to forming a comprehensive performance ticketing service system that is leading in Shaanxi, first-class in the west, and well-known nationwide. We have a number of functions such as online seat purchase, electronic ticket inspection, payment protection, etc., providing consumers with convenient and high-quality ticketing services, and presenting more diversified, more open and more colorful cultural and artistic performance activities. At the same time, we undertake the promotion and ticketing services for the performance projects of major troupes and organizers."
					}]
				}, {
					title: "舞台检测",
					entitle: "Stage Testing",
					model: [{
						title: "矩浪音乐节",
						entitle: "Moment Wave Music Festival",
						url: require("../assets/imgs/hxyw/wtjc_1.png"),
						text: "矩浪音乐节集多元音乐、文化、潮流娱乐于一体，打破时间的壁垒，跨越空间的距离。集结许嵩、小鬼玉琳凯、马思唯等20多组嘻哈流行艺人，唱“享“狂热舞台，引爆观众多巴胺,震撼古都西安，点燃夏日激情。陕西数字演艺科技有限公司舞台技术检测中心承担了此次矩浪音乐节舞台结构的安全检测任务，为您的观演安全保驾护航。",
						entext: "Moment Wave Music Festival integrates diversified music, culture and trend entertainment, breaking the barriers of time and crossing the distance of space. Gathering more than 20 groups of hip-hop artists such as Xu Song, Kid Yulin Kai, Ma Siwei, etc., the festival sings 'enjoy' the frenzied stage, triggering the audience's dopamine, shocking the ancient capital of Xi'an and igniting the summer passion. Shaanxi Digital Performing Arts Technology Co., Ltd. stage technology testing center undertook the safety inspection of the stage structure of the Moment Wave Music Festival, for your safety escort."
					}, {
						title: "中亚峰会",
						entitle: "Central Asian Summit",
						url: require("../assets/imgs/hxyw/wtjc_2.png"),
						text: "2023年5月18日-19日中国-中亚峰会在西安顺利召开，陕西数字演艺科技有限公司舞台技术检测中心承担了灯光、音响、舞台设备设施的安全检测任务，为中国-中亚峰会迎宾演出保驾护航。",
						entext: "May 18-19, 2023 China - Central Asia Summit was successfully held in Xi'an, Shaanxi Digital Performing Arts Technology Co., Ltd. stage technology testing center undertook the task of lighting, sound, stage equipment and facilities safety testing, for the China - Central Asia Summit welcome performance escort."
					}]
				}, {
					title: "文艺创造",
					entitle: "literary creation",
					model: [{
						title: "《生命之重》",
						entitle: "The Weight of Life",
						url: require("../assets/imgs/hxyw/wycz_1.png"),
						text: "由陕西数字演艺科技有限公司创作出品的先锋小剧场创意舞剧《生命之重》部关于成长的故事。作品借梅兰竹菊完成了四位舞者舞台形象的塑造，在花与人的交替转换中,将“四君子”不畏艰险，百折不挠的精神品格用舞蹈的方式呈现，同时这种镌刻在中华民族骨子里的伟大基因，也激励着舞者们迎难而上，奋勇前进。",
						entext: "The pioneering small theater creative dance drama 'The Weight of Life' is a story about growth created and produced by Shaanxi Digital Performing Arts Technology Co. The work borrows plum, orchid, bamboo and chrysanthemum to complete the four dancers stage image, in the alternation of flowers and people, the 'Four Gentlemen' defy difficulties and dangers, the indomitable spirit of the character of the way the dance is presented, and at the same time, this engraved in the bones of the Chinese nation's great genes, but also inspire the dancers to meet the challenges and move forward bravely."
					}, {
						title: "西部文博会",
						entitle: "Western Cultural Expo",
						url: require("../assets/imgs/hxyw/wycz_2.png"),
						text: "陕西演艺集团展厅参照中国古典建筑“天圆地方”的设计理念，以象征革命血脉薪火相传的红色为主基调，设置舞台观演区、成果展示区、拍照打卡区、商务洽谈区等7个板块，围绕集团重点剧目、企业文化、重大项目等进行多维度、全方位展示，各板块穿插剧目互动、剧情IP打卡及多平台直播等数字化、多媒体新技术新手段，带给广大观众沉浸式观展体验。",
						entext: "Shaanxi Performing Arts Group exhibition hall with reference to the design concept of classical Chinese architecture, 'heaven is round and earth is square', to symbolize the revolutionary lineage of the red as the main tone, set up a stage viewing area, the results of the display area, photo card area, business negotiation area and other seven plates, around the group's key repertoire, corporate culture, major projects, such as multi-dimensional, all-round display, the panels are interspersed with the repertoire interaction, the drama of IP card and multi-platform live broadcasting and other digital, multimedia technology and new means, to bring the majority of the audience an immersive experience of viewing the exhibition."
					}, {
						title: "第四届西部数字经济博览会",
						entitle: "The 4th Western Digital Economy Expo",
						url: require("../assets/imgs/hxyw/wycz_3.png"),
						text: "陕西数字演艺科技有限公司以“科技赋能，艺见美好”为主题亮相本次展会，吸引了各行各业专家学者参观洽谈，成为C馆的热议亮点。在本届博览会上，我司设立了三大板块：陕演票务中心、舞台安全检测中心、艺数空间，全面展示演艺数字化引领改革发展新高地。我们与各位行业院士专家、企业代表、合作伙伴和潜在客户进行深入交流，共同探讨数字经济发展的趋势和机遇。",
						entext: "Shaanxi Digital Performing Arts Technology Co., Ltd. appeared in this exhibition with the theme of " +
							"Technology Empowerment,Art and Beauty" +
							", which attracted experts and scholars from all walks of life to visit and negotiate, and became a hot topic of discussion in C Hall. In this year's fair, our company set up three major plates: Shaanxi Performance Ticketing Center, Stage Safety Inspection Center, Art Digital Space, a comprehensive display of performing arts digitalization to lead the reform and development of the new high ground. We have in-depth exchanges with all industry academicians and experts, business representatives, partners and potential customers to discuss the trends and opportunities of the development of the digital economy."
					}, {
						title: "中国（苏州）演出交易会",
						entitle: "China (Suzhou) Performance Fair",
						url: require("../assets/imgs/hxyw/wycz_4.png"),
						text: "陕西数字演艺科技有限公司受邀参加了此次演出交易会。我们期待通过本次论坛会，与各位行业精英、专家深入交流，共同探讨更多前瞻性的思路和方案，积极践行创新驱动发展的理念，推动全行业的智能化、数字化、品牌化、国际化升级，实现行业新一轮的繁荣与发展。数字陕演也将继续秉承创新、专业、务实的理念，为演艺行业的数字化转型和升级，提供更加专业的服务和支持,推动中国演艺行业的繁荣发展。",
						entext: "Shaanxi Digital Performing Arts Technology Co., Ltd. was invited to participate in this performance trade fair. We look forward to this forum, with all the industry elites, experts, in-depth exchanges, to discuss more forward-looking ideas and programs, and actively practice the concept of innovation-driven development, to promote the industry's intelligence, digitalization, branding, and internationalization upgrades, to achieve a new round of prosperity and development of the industry. Digital Shaanxi Performing Arts will continue to uphold the concept of innovation, professionalism and pragmatism, and provide more professional services and support for the digital transformation and upgrading of the performing arts industry to promote the prosperity of China's performing arts industry."
					}]
				}, {
					title: "艺术空间",
					entitle: "Art Space",
					model: [{
						title: "云游陕演",
						entitle: "Yunyou Shanshan Performance",
						url: require("../assets/imgs/hxyw/yskj.png"),
						text: "云游陕演是基于线下宣传及大型活动的无限延伸，通过有限的空间引导观众进入无限的云端展示，以提供更丰富更全面的资讯和体验，从而打破空间距离、时间差异、场馆容量等限制，提供更加丰富的体验方式、互动模式和传播形式，实现永不闭幕的数字化传播。",
						entext: "Cloud Tour Shaanxi Performance is based on the infinite extension of offline publicity and large-scale activities, through the limited space to guide the audience into the infinite cloud display, in order to provide richer and more comprehensive information and experience, so as to break the limitations of spatial distance, time differences, venue capacity, etc., to provide a richer way of experiencing, interactive modes and forms of dissemination, and to realize the digital dissemination of the never-ending closure."
					}]
				}],
			}
		},
		mounted() {
			 
		}, 
		watch: {
			i(newi, oldi) {
				if (this.ismobile) {
					this.removeM();
					document.querySelector(`.min_img_${this.i+1}>.min_img-item>.imgaction`).classList.add(
						"imgaction_active");
				} else {
					this.removeM();
					document.querySelector(`.img_${this.i+1}>.img-item>.imgaction`).classList.add("imgaction_active");
					document.querySelector(`.img_${this.i+1}>.img-item>.img-item-hover`).classList.add("active-cont");
				}
			},
			// minpopshow(newminpopshow, oldminpopshow) {
			// 	if (!newminpopshow) {
			// 		document.querySelectorAll(".imgaction").forEach(item => {
			// 			item.classList.remove("imgaction_active");
			// 		})
			// 	}
			// } 
		},
		methods: {
			enterimg(index) {
				if (!this.ismobile) { 
					this.$emit("enterimg");
					this.removeM();
					this.enterhoverindex = 0;
					document.querySelector(`.img_${index+1}>.img-item>.imgaction`).classList.add("imgaction_active");
				}
			},
			leaveimg(index) {
				if (!this.ismobile) {
					this.$emit("leaveimg"); 
					document.querySelector(`.img_${index+1}>.img-item>.imgaction`).classList.remove("imgaction_active");
				}
			},
			removeM() {
				document.querySelectorAll(".imgaction").forEach(item => {
					item.classList.remove("imgaction_active");
				})
				if (!this.ismobile) {
					document.querySelectorAll(".img-item-hover").forEach(item => {
						item.classList.remove("active-cont");
					})
				}
			},
			clickimg(index) {
				if (this.ismobile) { 
					this.enterhoverindex = 0;
					if(this.min_i != null) { 
						if(this.min_i != index) {  
							this.minpopshow = true;
						} else {
							if(this.minpopshow) {
								this.minpopshow = false;
							} else {
								this.minpopshow = true;
							} 
						}
					} else {
						this.minpopshow = true;
					}  
					this.min_i = index; 
					this.$emit("clickimg", this.minpopshow);
					this.removeM();
					document.querySelector(`.min_img_${index+1}>.min_img-item>.imgaction`).classList.add(
						"imgaction_active");  
				}
			},
			minimgclick() {
				if (this.ismobile) {
					this.minpopshow = false;
					this.$emit("minimgclick", this.minpopshow);
				}
			},
			enterhover(index) {
				this.enterhoverindex = index;
			}
		}
	}
</script>

<style scoped>
	.content {
		width: 100%;
		height: 100vh;
		background: #f4f4f4;
		padding: 0;
		box-sizing: border-box;
		z-index: 4;
		position: relative;
		overflow: hidden;
	}

	.min_content {
		width: 100%;
		height: auto;
		background: #f4f4f4;
		z-index: 5;
		position: relative;
	}

	.title {
		font-size: 34px;
		font-weight: normal;
		line-height: normal;
		letter-spacing: 0em;
		color: #333333;
		padding-left: 135px;
		padding-top: 54px;
		margin-bottom: 70px;
		z-index: 3;
		position: absolute;
		top: 0;
		left: 0;
	}

	.min_title {
		font-size: 26px;
		color: #333333;
		padding-left: 20px;
		padding-top: 36px;
		position: relative;
		z-index: 3;
		position: absolute;
		top: 0;
		left: 0;
	}

	.img {
		width: 100%;
		height: 100%;
		/* background: url("../assets/imgs/hxbg.png") no-repeat; */
		/* background-size: 100% 100%; */
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	}

	.min_img {
		width: 100%;
		height: 100%;
		position: absolute;
		top: 0;
		left: 0;
		/* background: url("../assets/imgs/min_hxbg.png") no-repeat; */
		/* background-size: 100% 100%; */
		/* overflow: hidden; */
	}

	.imgscont {
		width: auto;
		height: auto;
		display: flex;
		justify-content: center;
		align-items: center;
		position: relative;
	}

	.imgs_cont {
		position: relative;
	}

	.imgs {
		display: block;
		width: auto;
		height: 100vh;
		flex-basis: 100%;
		/* margin: 0 auto; */
	}

	.min_imgs {
		/* position: absolute;
		top: 0;
		left: 0; */
		display: block;
		width: 100%;
		height: auto;
	}



	.img_ {
		width: auto;
		height: auto;
		position: absolute;
		cursor: pointer;
		opacity: 1;

	}

	.img_active {
		opacity: 1;
	}

	.img_1 {
		top: 29%;
		left: 18.5%;
	}

	.min_img_1 {
		top: 200px;
		right: 35px;
		cursor: auto;
	}

	.img_2 {
		/* top: 150px;
		left: 570px; */
		top: 14%;
		left: 33%;
	}

	.min_img_2 {
		right: 75px;
		bottom: 100px;
		cursor: auto;
	}

	.img_3 {
		top: 11%;
		right: 21.5%;
	}

	.min_img_3 {
		top: 155px;
		left: 180px;
		cursor: auto;
	}

	.img_4 {
		left: 27.5%;
		bottom: 34%;
	}

	.min_img_4 {
		top: 285px;
		left: 105px;
		cursor: auto;
	}

	.img_5 {
		top: 26%;
		left: 45.6%;
	}

	.min_img_5 {
		top: 375px;
		left: 100px;
		cursor: auto;
	}

	.img_6 {
		top: 30%;
		right: 25%;
	}

	.min_img_6 {
		right: 40px;
		bottom: 330px;
		cursor: auto;
	}

	.img_7 {
		right: 38.5%;
		bottom: 28%;
	}

	.min_img_7 {
		left: 50px;
		bottom: 120px;
		cursor: auto;
	}

	.img_8 {
		right: 29.5%;
		bottom: 38%;
	}

	.min_img_8 {
		left: 130px;
		bottom: 170px;
		cursor: auto;
	}

	.img_9 {
		top: 43%;
		right: 16.5%;
	}

	.min_img_9 {
		right: 95px;
		bottom: 240px;
		cursor: auto;
	}

	.img-item {
		width: 80px;
		height: 50px;
		position: relative;
	}

	.min_img-item {
		position: relative;
		width: 30px;
		height: 30px;
	}

	.img-item:hover .img-item-hover {
		display: block;
	}

	.active-cont {
		display: block !important;
	}

	.img-item-hover {
		display: none;
		position: absolute;
		top: 50%;
		top: 0;
		left: 80px;
		/* transform: translateY(-50%); */
		width: 407px;
		/* height: 193px; */
		border-radius: 5px;
		background: #FFFFFF;
		z-index: 3;
	}

	.img_4 .img-item-hover,
	.img_7 .img-item-hover,
	.img_8 .img-item-hover {
		transform: translateY(-50%);
	}


	.img_9 .img-item-hover {
		left: auto;
		right: 80px;
	}

	.min_img-item-hover {
		width: 262px;
		height: auto;
		/* display: none; */
		position: absolute;
		/* top: 50px; */
		/* left: 50%;
		transform: translateX(-50%); */
		border-radius: 5px;
		background: #FFFFFF;
		z-index: 3;
		overflow: hidden;
	}

	.min_img_1 .min_img-item-hover {
		top: 50px;
		right: 0;
	}

	.min_img_2 .min_img-item-hover {
		bottom: 50px;
		right: 0;
	}

	.min_img_3 .min_img-item-hover {
		top: 50px;
		left: 50%;
		transform: translateX(-50%);
	}

	.min_img_4 .min_img-item-hover {
		top: 50px;
		left: -100%;
	}

	.min_img_5 .min_img-item-hover {
		top: 50px;
		left: -100%;
	}

	.min_img_6 .min_img-item-hover {
		right: 0;
		bottom: 50px;
	}

	.min_img_7 .min_img-item-hover {
		left: 0;
		bottom: 50px;
	}

	.min_img_8 .min_img-item-hover {
		left: -100%;
		bottom: 50px;
	}

	.min_img_9 .min_img-item-hover {
		right: -100%;
		bottom: 50px;
	}

	.min_img-item-hover>:nth-child(1) {
		background: linear-gradient(180deg, #FFF6EC 2%, rgba(255, 255, 255, 0.00) 58%);
	}

	.hover-title {
		font-size: 16px;
		font-weight: 600;
		line-height: 20px;
		letter-spacing: 0em;
		color: #333333;
		padding: 15px 40px;
		border: 1px solid #F6F6F6;
	}

	.min_hover-title {
		font-size: 16px;
		font-weight: normal;
		line-height: 16px;
		letter-spacing: 0em;
		padding: 15px 20px;
		color: #333333;
		border: 1px solid #F6F6F6;
	}

	.hover-tap {
		font-size: 14px;
		font-weight: 250;
		line-height: 20px;
		letter-spacing: 0em;
		padding: 8px 40px;
		color: #CCCCCC;

	}

	.min_hover-tap {
		font-size: 14px;
		font-weight: normal;
		line-height: 14px;
		letter-spacing: 0em;
		padding: 0 20px 15px 20px;
		color: #CCCCCC;
		display: flex;
		justify-content: space-between;
		align-items: center;
		border: 1px solid #F6F6F6;
		flex-wrap: wrap;
	}

	.min_hover-tap>span { 
		text-align: center;
		display: block;
		padding-top: 15px;
	}
	.min_hover-tap_1 >span {
		width: auto;
	}
	.min_hover-tap_2 >span {
		width: 50%;
	}

	.hover-tap-active {
		color: #333333;
	}

	.hover-tap>span {
		width: auto;
		min-width: 50%;
		display: inline-block;
	}

	.hover-tap>span:last-child {
		/* padding-right: 0; */
	}

	.hover-cont {
		width: 100%;
		/* height: 105px; */
		background: #FAFAFA;
		padding: 17px 40px;
		box-sizing: border-box;
	}

	.hover-cont>div {
		width: 100%;
		/* height: 105px; */
		display: flex;
	}

	.min_hover-cont {
		width: 100%;
		padding: 15px 20px;
		box-sizing: border-box;
	}

	.hover-cont-img {
		width: 67px;
		height: 67px;
		margin-right: 20px;
		flex-shrink: 0;
		display: flex;
		justify-content: center;
		align-items: center;
		overflow: hidden;
	}

	.hover-cont-img>img {
		height: 100%;
		width: auto;
	}

	.min_hover-cont-img {
		width: 100%;
		height: 125px;
		border-radius: 5px;
		overflow: hidden;
	}

	.hover-cont-text {
		font-size: 12px;
		font-weight: normal;
		line-height: 24px;
		letter-spacing: 0em;
		color: #999999;
	}

	.min_hover-cont-text {
		font-size: 12px;
		line-height: 20px;
		color: #666666;
		margin-top: 14px;
	}

	.imgaction {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%) rotate(-45deg);
		display: flex;
		justify-content: center;
		align-items: center;
	}

	.imgaction_in {
		width: 20px;
		height: 20px;
		background: #ffc433;
		border-radius: 8px;
		display: flex;
		justify-content: center;
		align-items: center;
	}

	.imgaction_item {
		width: 10px;
		height: 10px;
		background: #fff;
		border-radius: 4px;
	}

	.imgaction_active {
		animation: imgaction 2s linear 0.2s infinite;
	}

	.imgaction_active>div {
		animation: imgaction_in 0.1s linear forwards;
	}

	.imgaction_active>div>div::after {
		content: '';
		display: block;
		position: absolute;
		left: 50%;
		top: 50%;
		z-index: -1;
		transform: translate(-50%, -50%);
		border-radius: 8px;
		width: 20px;
		height: 20px;
		background: rgba(255, 156, 51, 0.3);
		animation: boderM 3s 0.1s infinite;
	}

	.imgaction_active>div>div::before {
		content: '';
		display: block;
		position: absolute;
		left: 50%;
		top: 50%;
		z-index: -1;
		transform: translate(-50%, -50%);
		border-radius: 8px;
		width: 20px;
		height: 20px;
		background: rgba(255, 153, 51, 0.4);
		animation: boderM 3s 0.75s infinite;
	}

	@keyframes imgaction_in {
		0% {
			transform: scale(1);
		}

		100% {
			transform: scale(1.5);
		}
	}

	@keyframes imgaction {

		50% {
			/* background: #ff9933; */
			box-shadow: 0px 0px 10px 0 #ff9933;
		}
	}

	@keyframes boderM {
		0% {
			transform: translate(-50%, -50%) scale(1);
			opacity: 0
		}

		20% {
			opacity: 1
		}

		75% {
			transform: translate(-50%, -50%) scale(2);
			opacity: 0
		}

		100% {
			opacity: 0
		}

	}

	/* @keyframes imgaction_in {
		0% {
			background: transparent;
		}

		100% {
			background: #ffc433;
		}
	} */
</style>