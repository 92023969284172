import {
	createApp
} from 'vue'
import App from './App.vue'
import router from './router'
import 'animate.css';
import axios from 'axios'
import '@/utils/rem.js'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import * as ElementPlusIconsVue from '@element-plus/icons-vue' 
// import VConsole from 'vconsole';


const app = createApp(App);
app.use(ElementPlus)
app.use(router)
 

app.config.globalProperties.$axios = axios;  
app.mount('#app'); //将这个vue3app全局挂载到#app元素上

for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
    app.component(key, component);
}

// if (process.env.NODE_ENV === 'development') {
//   const vConsole = new VConsole();
// }