<template>
	<div>
		<div class="navbox" :style="`color: ${color};background: ${background};`" v-if="!ismobile">
			<div class="logo">
				<img src="../assets/imgs/logo.png" v-show="writeshow" alt="">
				<img src="../assets/imgs/logo_black.png" v-show="!writeshow" alt="">
			</div>
			<div class="rightlist">
				<ul class="tabnav" v-show="enchange">
					<li v-for="(item,index) in navlist" :key="index" :class="{'tabnav-active': circleindex == index}"
						@click="circlebtn(index)">{{item}}</li>
				</ul>
				<ul class="tabnav" v-show="!enchange">
					<li v-for="(item,index) in ennavlist" :key="index" :class="{'tabnav-active': circleindex == index}"
						@click="circlebtn(index)">{{item}}</li>
				</ul>
				<!-- <div class="search" v-show="btnshow">
					<img src="../assets/imgs/search.png" v-show="writeshow" alt="">
					<img src="../assets/imgs/search_black.png" v-show="!writeshow" alt="">
				</div> -->
				<div class="ENchange" v-show="btnshow">
					<div class="changeEN" v-show="enchange" @click="enchangebtn">EN</div>
					<div class="changeEN" v-show="!enchange" @click="enchangebtn">CN</div>
				</div>
			</div>
		</div>
		<div class="min_navbox" :style="`color: ${color};background: ${background};`" v-else>
			<div class="min_logo">
				<img src="../assets/imgs/logo.png" v-show="writeshow" alt="">
				<img src="../assets/imgs/logo_black.png" v-show="!writeshow" alt="">
			</div>
			<div class="rightlist">
				<!-- <div class="min_search" v-show="btnshow">
					<img src="../assets/imgs/search.png" v-show="writeshow" alt="">
					<img src="../assets/imgs/search_black.png" v-show="!writeshow" alt="">
				</div> -->
				<div class="min_navbtn" @click="minnavopen">
					<img src="../assets/imgs/min_nav.png" v-show="writeshow" alt="">
					<img src="../assets/imgs/min_nav_black.png" v-show="!writeshow" alt="">
				</div>

				<div class="min_navpop" v-show="popshow">
					<div class="min_nav_top" @click="minnavclose">
						<div class="min_logo">
							<img src="../assets/imgs/logo.png" alt="">
						</div>
						<div class="min_navclose">
							<img src="../assets/imgs/min_close.png" alt="">
						</div>
					</div>
					<ul class="min_tabnav" v-show="enchange">
						<li v-for="(item,index) in navlist" :key="index" @click="circlebtn(index)">
							<!-- :class="{'min_tabnav_active': index == circleindex}" -->
							<span>{{item}}</span>
							<div><img src="../assets/imgs/nav_right.png" alt=""></div>
						</li>
					</ul>
					<ul class="min_tabnav" v-show="!enchange">
						<li v-for="(item,index) in ennavlist" :key="index" @click="circlebtn(index)">
							<span>{{item}}</span>
							<div><img src="../assets/imgs/nav_right.png" alt=""></div>
						</li>
					</ul>


					<div class="min_ENchange" @click="enchangebtn">
						<div><img src="../assets/imgs/changeEN.png" alt=""></div>
						<span v-show="enchange">切换至英文</span>
						<span v-show="!enchange">切换至中文</span>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		props: {
			ismobile: {
				type: Boolean,
				default: false
			},
			writeshow: {
				type: Boolean,
				default: true,
			},
			enchange: {
				type: Boolean,
				default: true
			},
			circleindex: {
				type: Number,
				default: 0
			},
			navlist: {
				type: Array,
				default: null
			},
			ennavlist: {
				type: Array,
				default: null
			},
			btnshow: {
				type: Boolean,
				default: true
			},
			color: {
				type: String,
				default: "#fff"
			},
			background: {
				type: String,
				default: "transparent"
			},
		},
		data() {
			return {
				popshow: false,
			}
		},
		methods: {
			circlebtn(index) {
				if (this.ismobile) {
					this.canScroll();
					document.querySelector('.min_navpop').classList.add('min_navpop_open_up');
					setTimeout(() => {
						this.popshow = false;
					}, 1000)
				}
				this.$emit('circlebtn', index);
			},
			enchangebtn() {
				if(this.ismobile) { 
					this.canScroll();
					document.querySelector('.min_navpop').classList.add('min_navpop_open_up');
					setTimeout(() => {
						this.popshow = false; 
					}, 1000)
				}
				this.$emit('enchangebtn');
			},
			// 打开导航栏
			minnavopen() {
				this.popshow = true;
				this.stopScroll();
				document.querySelector('.min_navpop').classList.add('min_navpop_open');
				document.querySelector('.min_navpop').classList.remove('min_navpop_open_up');
			},
			// 关闭导航栏
			minnavclose() {
				this.canScroll();
				document.querySelector('.min_navpop').classList.remove('min_navpop_open');
				document.querySelector('.min_navpop').classList.add('min_navpop_open_up');
				setTimeout(() => {
					this.popshow = false; 
				}, 1000)
			},
			stopScroll() {
				var mo = function(e) {
					e.preventDefault();
				};
				document.body.style.overflow = 'hidden';
				document.addEventListener("touchmove", mo, false); //禁止页面滑动 
			},
			/***取消滑动限制***/
			canScroll() {
				var mo = function(e) {
					e.preventDefault();
				};
				document.body.style.overflow = ''; //出现滚动条 
				document.removeEventListener("touchmove", mo, false);
			}
		}
	}
</script>

<style scoped>
	.navbox {
		position: fixed;
		top: 0;
		width: 100%;
		height: 70px;
		z-index: 9;
		padding: 0 135px;
		box-sizing: border-box;
		display: flex;
		align-items: center;
		justify-content: space-between;
	}

	.min_navbox {
		position: fixed;
		top: 0;
		width: 100%;
		height: 60px;
		z-index: 9;
		padding: 0 20px;
		box-sizing: border-box;
		display: flex;
		align-items: center;
		justify-content: space-between;
	}

	.logo {
		width: 161px;
		height: 27px;
	}

	.min_logo {
		width: 106px;
		height: 18px;
		flex-shrink: 0;
	}

	.rightlist {
		display: flex;
		align-items: center;
	}

	.tabnav {
		height: 70px;
		font-size: 18px;
		display: flex;
	}

	.min_tabnav {
		display: flex;
		flex-direction: column;
		padding: 36px 20px 0 20px;
	}

	.min_tabnav>li {
		height: 64px;
		font-size: 16px;
		font-weight: normal;
		line-height: 36px;
		color: #FFFFFF;
		/* margin-bottom: 33px; */
		/* opacity: 0; */
		display: flex;
		align-items: center;
		justify-content: space-between;
		border-bottom: 1px solid #726868;
	}

	.min_tabnav_active {
		background: rgba(196, 157, 98, .3);
	}

	.min_tabnav>li>div {
		width: 18px;
		height: 18px;
		display: flex;
		justify-content: center;
		align-items: center;
	}

	.min_tabnav>li:last-child {
		margin-bottom: 0;
		border-bottom: none;
	}

	.tabnav>li {
		height: 70px;
		line-height: 70px;
		margin-right: 80px;
		cursor: pointer;
	}

	.tabnav>li:last-child {
		margin-right: 0;
	}

	.tabnav-active {
		color: #C49D62;
		position: relative;
	}

	.tabnav-active::before {
		content: '';
		display: block;
		width: 100%;
		height: 3px;
		border-radius: 5px;
		background: #C49D62;
		position: absolute;
		left: 50%;
		bottom: 0;
		transform: translateX(-50%);
		animation: width 0.3s linear;
	}

	@keyframes width {
		0% {
			width: 0;
		}

		100% {
			width: 100%;
		}
	}

	.search {
		/* width: 24px; */
		height: 24px;
		padding: 0 100px;
		flex-shrink: 0;
	}

	.min_search {
		height: 19px;
		/* padding: 0 20px; */
		flex-shrink: 0;
	}

	.changeEN {
		width: 25px;
		text-align: center;
		font-size: 18px;
		cursor: pointer;
		user-select: none;
	}

	.min_navbtn {
		width: 17px;
		height: 16px;
		margin-left: 20px;
	}

	/* /deep/ .el-dropdown-menu__item:not(.is-disabled):active {
		background: rgba(195, 157, 98, 0.3) !important;
		color: #C49D62 !important;
	} */


	/deep/ .el-dropdown-menu__item:not(.is-disabled) {
		color: #333;
	}


	.min_navpop {
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: 100vh;
		background: rgba(0, 0, 0, 0.5);
		backdrop-filter: blur(10px);
		z-index: 99;

	}

	.min_navpop_open {
		animation: navmove 1s ease forwards;
	}

	.min_navpop_open>ul>li {
		/* animation: navlistmove 0.5s linear 0.5s forwards; */
	}

	.min_navpop_open_up {
		animation: navmove_up 1s ease forwards;
	}

	.min_navpop_open_up>ul>li {
		/* animation: navlistmove_up 0.5s linear forwards; */
	}

	@keyframes navmove {
		0% {
			top: -100vh;
		}

		100% {
			top: 0;
		}
	}

	@keyframes navmove_up {
		0% {
			top: 0;
		}

		100% {
			top: -100vh;
		}
	}


	@keyframes navlistmove {
		0% {
			opacity: 0;
			transform: translateY(30px) rotateX(180deg);
		}

		100% {
			opacity: 1;
			transform: translateY(0px) rotateX(0deg);
		}
	}

	@keyframes navlistmove_up {
		0% {
			opacity: 1;
			transform: translateY(0px) rotateX(0deg);
		}

		100% {
			opacity: 0;
			transform: translateY(30px) rotateX(90deg);
		}
	}


	.min_nav_top {
		height: 60px;
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: 0 20px;
	}

	.min_navclose {
		width: 17px;
		height: 18px;
	}

	.ENchange {
		margin-left: 100px;
	}

	.min_ENchange {
		font-size: 16px;
		font-weight: normal;
		line-height: 16px;
		color: #FFFFFF;
		display: flex;
		width: 100%;
		justify-content: center;
		align-items: center;
		position: absolute;
		left: 0;
		bottom: 150px;
	}

	.min_ENchange>div {
		width: 19px;
		height: 19px;
		margin-right: 9px;
	}
</style>