/****   request.js   ****/
// 导入axios
import axios from 'axios';
// import {Message} from 'element-ui';
const service = axios.create({
	// method: "GET",
	// baseURL: 'http://topartsintermational.com:18031',
	// timeout: 5 * 1000
})
service.interceptors.request.use(config => {
	config.data = config.data;
	config.headers = {

	};
	config.contentType = "application/json";
	// //注意使用token的时候需要引入cookie方法或者用本地localStorage等方法，推荐js-cookie
	const token = localStorage.getItem('hxtoken'); //这里取token之前，你肯定需要先拿到token,存一下
	if (token) {
		config.headers.Authorization = `Bearer ${token}`; //如果要求携带在请求头中
	}
	return config
}, error => {
	Promise.reject(error)
})

service.interceptors.response.use(
	(response) => {
		const res = response
		return res
	},
	(error) => {
		let res = error.response
		if (error.response) {
			switch (res.status) {
				case 400:
					error.message = '错误请求'
					break;
				case 401:
					// error.message = '未授权，请重新登录'
					// break;
				case 403:
					error.message = '拒绝访问'
					break;
				case 404:
					error.message = '请求错误,未找到该资源'
					break;
				case 405:
					error.message = '请求方法未允许'
					break;
				case 408:
					error.message = '请求超时'
					break;
				case 500:
					error.message = '服务器端出错'
					break;
				case 501:
					error.message = '网络未实现'
					break;
				case 502:
					error.message = '网络错误'
					break;
				case 503:
					error.message = '服务不可用'
					break;
				case 504:
					error.message = '网络超时'
					break;
				case 505:
					error.message = 'http版本不支持该请求'
					break;
				default:
					error.message = `连接错误,请刷新重试`
					// ${error.response.status}
			}
		}
		console.log(error.message)
		// Message({
		//   showClose: true,
		//   message: error.message,
		//   type: 'error'
		// });
		return Promise.reject(error)
	}
)
//4.导入文件
export default service