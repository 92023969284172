<template>
	<div>
		<div :class="ismobile ? 'min_content': 'content'">
			<div class="animated" :class="ismobile ?'min_backcolor': 'backcolor'"></div>
			<div class="animated" :class="ismobile ?'min_product':'product'">
				<div :class="ismobile ? 'min_title': 'title'" v-show="enchange">
					<div v-for="(item,index) in title" :key="index">{{item}}</div>
				</div>
				<div :class="ismobile ? 'min_entitle': 'entitle'" v-show="!enchange">
					<div v-for="(item,index) in entitle" :key="index">{{item}}</div>
				</div>
				<ul class="product-item" v-if="!ismobile">
					<li class="product-item-list" :class="{'enproduct-item-list':!enchange}"
						v-for="(item,index) in productdata" :key="index">
						<div class="pro-img">
							<img :src="item.img" alt="">
						</div>
						<div class="pro-text" v-show="enchange">{{item.text}}</div>
						<div class="pro-text" v-show="!enchange">{{item.entext}}</div>
					</li>
				</ul>
				<ul class="min_product-item" v-else>
					<li class="min_product-item-list" :class="{'enproduct-item-list':!enchange}"
						v-for="(item,index) in productdata" :key="index">
						<div class="min_pro-img">
							<img :src="item.img" alt="">
						</div>
						<div class="min_pro-text" v-show="enchange">{{item.text}}</div>
						<div class="min_pro-text" v-show="!enchange">{{item.entext}}</div>
					</li>
					<i></i>
				</ul>
			</div>
			<div class="animated" :class="ismobile ? 'min_img':'img'"></div>
		</div>
	</div>
</template>

<script>
	export default {
		props: {
			ismobile: {
				type: Boolean,
				default: false
			},
			topi: {
				type: Number,
				default: null
			},
			enchange: {
				type: Boolean,
				default: true
			}
		},
		data() {
			return {
				title: ["以“科技赋能 艺见美好”为理念，", " 探索数字与创意 链接人文与情感。"],
				entitle: ['With the concept of "Technology Empowerment, Art is Beautiful",',
					' we explore digital and creativity, linking humanities and emotions.'
				],
				productdata: [{
					img: require("../assets/imgs/project/1.png"),
					text: "艺数空间",
					entext: "Art Space",
				}, {
					img: require("../assets/imgs/project/2.png"),
					text: "票务平台",
					entext: "Ticketing platforms",
				}, {
					img: require("../assets/imgs/project/3.png"),
					text: "文创销售",
					entext: "Cultural and Creative Sales",
				}, {
					img: require("../assets/imgs/project/4.png"),
					text: "数字办公",
					entext: "digital office",
				}, {
					img: require("../assets/imgs/project/5.png"),
					text: "舞台检测",
					entext: "Stage detection",
				}, {
					img: require("../assets/imgs/project/6.png"),
					text: "重点会议",
					entext: "Focused meetings",
				}, {
					img: require("../assets/imgs/project/7.png"),
					text: "申报系统",
					entext: "Filing system",
				}, {
					img: require("../assets/imgs/project/8.png"),
					text: "数字创作",
					entext: "digital creations",
				}, {
					img: require("../assets/imgs/project/9.png"),
					text: "多媒体传播",
					entext: "multimedia communications",
				}, {
					img: require("../assets/imgs/project/10.png"),
					text: "西部文博会",
					entext: "Western Cultural Expo",
				}, {
					img: require("../assets/imgs/project/11.png"),
					text: "演艺大数据",
					entext: "Big Data in Performing Arts",
				}, {
					img: require("../assets/imgs/project/12.png"),
					text: "文旅多模态",
					entext: "Cultural and Tourism Multimodal",
				}, {
					img: require("../assets/imgs/project/13.png"),
					text: "多媒体舞剧",
					entext: "Multimedia Dance Theater",
				}, {
					img: require("../assets/imgs/project/14.png"),
					text: "舆情信息分析",
					entext: "Public Opinion Information Analysis",
				}]
			}
		},
		watch: {
			topi(newtopi, oldtopi) {
				if (!this.ismobile) {
					if (newtopi == 1) {
						document.querySelector(".backcolor").classList.add("fadeInLeft");
						document.querySelector(".product").classList.add("fadeInLeft");
						document.querySelector(".img").classList.add("fadeInRight");
					} else {
						document.querySelector(".backcolor").classList.remove("fadeInLeft");
						document.querySelector(".product").classList.remove("fadeInLeft");
						document.querySelector(".img").classList.remove("fadeInRight");
					}
				} else {
					if (newtopi == 1) {
						document.querySelector(".min_backcolor").classList.add("fadeInLeft");
						document.querySelector(".min_product").classList.add("fadeInLeft");
						document.querySelector(".min_img").classList.add("fadeInRight");
					} else {
						document.querySelector(".min_backcolor").classList.remove("fadeInLeft");
						document.querySelector(".min_product").classList.remove("fadeInLeft");
						document.querySelector(".min_img").classList.remove("fadeInRight");
					}
				}
			}
		}
	}
</script>

<style scoped>
	.content {
		width: 100%;
		height: 100vh;
		position: relative;
		z-index: 5;
		background: #fff;
		overflow: hidden;
	}

	.min_content {
		width: 100%;
		height: auto;
		min-height: 100vh;
		position: relative;
		background: #fff;
		z-index: 5;
	}

	.backcolor {
		width: 1496px;
		height: 724px;
		background: #E3A95F;
		box-sizing: border-box;
		position: relative;
	}

	.min_backcolor {
		width: 100%;
		height: 100%;
		background: #E3A95F;
		box-sizing: border-box;
		position: absolute;
	}

	.product {
		width: 1496px;
		height: 724px;
		background: transparent;
		padding: 86px 152px 104px 304px;
		box-sizing: border-box;
		position: absolute;
		top: 0;
		left: 0;
		z-index: 9;
	}

	.min_product {
		width: 100%;
		height: 100%;
		background: transparent;
		box-sizing: border-box;
		position: relative;
		/* top: 0;
		left: 0; */
		z-index: 9;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		padding: 40px 0;
		box-sizing: border-box;
	}

	.img {
		position: absolute;
		right: 0;
		bottom: 0;
		width: 1400px;
		height: 580px;
		background: url("../assets/imgs/porbg.png") no-repeat;
		background-size: cover;
		z-index: 8;
	}

	.min_img {
		position: absolute;
		right: 0;
		bottom: 0;
		width: 100%;
		height: 100%;
		background: url("../assets/imgs/min_porbg.png") bottom right no-repeat;
		/* background-size: auto 100%; */
		z-index: 8;
	}

	.title {
		font-size: 40px;
		font-weight: normal;
		line-height: 61px;
		letter-spacing: 0em;
		color: #FFFFFF;
		margin-bottom: 98px;
	}

	.min_title {
		font-size: 20px;
		font-weight: normal;
		line-height: 29px;
		letter-spacing: 0em;
		color: #FFFFFF;
		margin-bottom: 50px;
		text-align: center;
	}

	.entitle {
		font-size: 32px;
		font-weight: normal;
		line-height: 61px;
		letter-spacing: 0em;
		color: #FFFFFF;
		margin-bottom: 98px;
	}

	.min_entitle {
		font-size: 18px;
		font-weight: normal;
		line-height: 24px;
		letter-spacing: 0em;
		color: #FFFFFF;
		margin-bottom: 20px;
		padding: 0 20px;
		text-align: center;
	}

	.product-item {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
	}

	.min_product-item {
		padding: 0 44px;
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		box-sizing: border-box;
	}

	.min_product-item>i {
		width: 70px;
	}

	.product-item-list {
		margin-bottom: 84px;
		margin-right: 60px;
		display: flex;
		flex-direction: column;
		align-items: center;
		width: 96px;
		cursor: pointer;
	}

	.min_product-item-list {
		margin-bottom: 50px;
		margin-right: 33px;
		display: flex;
		flex-direction: column;
		align-items: center;
		width: 70px;
	}

	.enproduct-item-list {
		margin-bottom: 42px;
	}

	.product-item-list:hover .pro-img {
		transform: rotateY(180deg);
	}

	.product-item-list:nth-child(7n) {
		margin-right: 0;
	}

	.min_product-item-list:nth-child(3n) {
		margin-right: 0;
	}

	.product-item-list:nth-child(n+8) {
		margin-bottom: 0;
	}

	.min_product-item-list:nth-child(n+12) {
		margin-bottom: 0;
	}

	.pro-img {
		width: 80px;
		height: 80px;
		margin-bottom: 24px;
		transition: all 0.5s;
	}

	.min_pro-img {
		width: 40px;
		height: 40px;
		margin-bottom: 17px;
		transition: all 0.5s;
	}

	.pro-text {
		font-size: 16px;
		font-weight: 500;
		line-height: 16px;
		letter-spacing: 0em;
		color: #FFFFFF;
		text-align: center;
	}

	.min_pro-text {
		font-size: 10px;
		font-weight: normal;
		line-height: 10px;
		letter-spacing: 0em;
		color: #FFFFFF;
		text-align: center;
	}
</style>