import Mobile from "./Mobile.js"
// 设置 rem 函数
function setRem(width) {
	// 1920 默认大小16px; 1920px = 120rem ;每个元素px基础上/16
	const screenWidth = width 
	const scale = screenWidth / 16
	const htmlWidth = document.documentElement.clientWidth || document.body.clientWidth
	// 得到html的Dom元素
	const htmlDom = document.getElementsByTagName('html')[0]
	// 设置根元素字体大小
	htmlDom.style.fontSize = htmlWidth / scale + 'px'
}
// 改变窗口大小时重新设置 rem
window.addEventListener('resize', function() {
	if (Mobile.isMobile()) {
		setRem(375);
	} else {
		setRem(1920);
	}
});

// 初始化
if (Mobile.isMobile()) {
	setRem(375)
} else {
	setRem(1920)
}