<template>
	<div>
		<div :class="ismobile ? 'min_content' : 'content'">
			<div :class="ismobile ? 'min_content_box': 'content_box'">
				<div :class="ismobile ? 'min_content-circle' : 'content-circle'">
					<div :class="ismobile ? 'min_logo_': 'logo_'">
						<img src="../assets/imgs/logo-circle.png" alt="">
					</div>
				</div>
				<div :class="ismobile ? 'min_content-item' : 'content-item'">
					<div :class="ismobile ? 'min_logo': 'logo'">
						<img src="../assets/imgs/big-logo.png" alt="">
					</div>
					<div v-show="enchange">
						<div :class="ismobile ? 'min_text': 'text'" v-show="textshow">{{solgn}}</div>
					</div>
					<div v-show="!enchange">
						<div :class="ismobile ? 'min_entext': 'entext'" v-show="textshow">{{ensolgn}}</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		props: {
			ismobile: {
				type: Boolean,
				default: false
			},
			enchange: {
				type: Boolean,
				default: true
			},
			textshow: {
				type: Boolean,
				default: false
			},
			scrollTop: {
				type: Number,
				default: null
			},
			clientheight: {
				type: Number,
				default: null
			}
		},
		watch: {
			scrollTop(newscrollTop,oldscrollTop) {
				if(this.ismobile) {
					// console.log(newscrollTop/this.clientheight)
					if(newscrollTop >= this.clientheight ) {
						document.querySelector(".min_content").classList.add("min_content_move");
					} else {
						document.querySelector(".min_content").classList.remove("min_content_move");
					}
				} 
			}
		},
		data() {
			return {
				solgn: "科技赋能·艺见美好",
				ensolgn: "Technology Empowerment - Art of Seeing Beauty",
			}
		},
	}
</script>

<style scoped>
	.content {
		width: 100%;
		height: 300vh;
		display: flex;
		flex-direction: column;
		align-items: center;
	}
	.content_box {
		width: 100%;
		height: 100vh;
		position: sticky;
		top: 0;
	}
	.min_content_box {
		width: 100%;
		height: auto;
		position: static;
	}

	.min_content {
		width: 100%;
		height: 100vh;
		position: relative; 
	}

	.content-circle {
		width: 100%;
		height: 100vh;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		position: sticky;
		top: 0;
		z-index: 3;
		background: #fff;
		opacity: var(--i-opacity);
		position: absolute;
		top: 0;
		left: 0;
	}

	.min_content-circle {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		opacity: 0;
	}

	.logo_ {
		width: 75px;
		height: 75px;
		transition: all 0.5s;
		transform: translateX(var(--i-left));
	}

	.min_logo_ {
		width: 33px;
		height: 33px;
		/* transform: translateX(var(--i-left)); */
		/* animation: logo_move1 1s linear 3s forwards; */
	}

	.content-item {
		width: 100%;
		height: 100vh;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		position: sticky;
		top: 0;
		transform: scale(var(--i-logoscale));
		transition: all 1s;
		position: absolute;
		top: 0;
		left: 0;
	}

	.min_content-item {
		width: 100%;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%) scale(1);
		display: flex;
		flex-direction: column;
		align-items: center;
		/* animation: logo_move5 1s linear 7s forwards; */
	}


	.logo {
		width: 257px;
		height: 246px;
		opacity: var(--i-logo);
		transition: all 0.5s;
		transform: translateY(var(--i-logotop));
	}

	.min_logo {
		width: 112px;
		height: 107px;
		/* opacity: var(--i-logo); */
		opacity: 0;
		transition: all 0.5s;
		/* transform: translateY(var(--i-logotop)); */
		/* animation: logo_move2 1s linear 4s forwards; */

	}

	.min_logo>img {
		transform: translateY(8px);
		/* animation: logo_move3 1s linear 5s forwards; */
	} 

	.text {
		font-size: 37px;
		font-weight: normal;
		line-height: 36px;
		letter-spacing: 1.01em;
		color: #333333;
		opacity: var(--i-textopacity);
		transition: all 1s;
	}

	.min_text {
		font-size: 17px;
		font-weight: normal;
		line-height: 16px;
		letter-spacing: 1em;
		color: #333333;
		/* opacity: var(--i-textopacity); */
		display: block !important;
		/* transition: all 1s; */
		opacity: 0;
		/* animation: logo_move4 1s linear 6s forwards; */
	} 

	.entext {
		font-size: 37px;
		font-weight: normal;
		line-height: 36px;
		color: #333333;
		opacity: var(--i-textopacity);
		transition: all 1s;
	}

	.min_entext {
		font-size: 17px;
		font-weight: normal;
		line-height: 16px;
		color: #333333;
		/* opacity: var(--i-textopacity); */
		display: block !important;
		/* transition: all 1s; */
		opacity: 0;
	} 
	.min_content_move>div>:nth-child(1) {
		opacity: 1;
	}
	.min_content_move>div>:nth-child(1)>div {
		animation: logo_move1 0.5s linear forwards;
	}
	.min_content_move>div>:nth-child(2)>:first-child {
		animation: logo_move2 0.5s linear 0.5s forwards;
	}
	.min_content_move>div>:nth-child(2)>:first-child>img {
		animation: logo_move3 0.5s linear 1s forwards;
	}
	.min_content_move>div>:nth-child(2)>:nth-child(2)>div{
		animation: logo_move4 0.5s linear 1.5s forwards;
	}
	.min_content_move>div>:nth-child(2)>:nth-child(3)>div{
		animation: logo_move4 0.5s linear 1.5s forwards;
	}
	.min_content_move>div>:nth-child(2) {
		animation: logo_move5 0.5s linear 2s forwards;
	}
	@keyframes logo_move1 {
		0% {
			display: block;
			transform: translateX(0px);
		}

		100% {
			display: none;
			opacity: 0;
			transform: translateX(-38px);
		}
	}

	@keyframes logo_move2 {
		0% {
			opacity: 0;
		}

		100% {
			opacity: 1;
		}
	}

	@keyframes logo_move3 {
		0% {
			transform: translateY(8px);
		}

		100% {
			transform: translateY(-75px);
		}
	}

	@keyframes logo_move4 {
		0% {
			opacity: 0;
		}

		100% {
			opacity: 1;
		}
	}

	@keyframes logo_move5 {
		0% {
			transform: translate(-50%, -50%) scale(1);
		}

		100% {
			transform: translate(-50%, -50%) scale(0.8);
		}
	}
</style>