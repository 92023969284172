import http from './utils.js'
let resquest = "http://www.sn-digitalarts.com.cn:19091"
//参数依次为接口 请求类型 请求参数 是否开启loading 是否为CDN基地址
export default {
	// 获取文章所有分类
	getcategories(params) {
		return http.get(`${resquest}/wp-json/wp/v2/categories`, params)
	},
	// 根据分类id获取改分类下的所有文章
	getposts(params) {
		return http.get(`${resquest}/wp-json/wp/v2/posts`, params)
	},
	// 根据文章id查询文章详情
	getpostslist(id) {
		return http.get(`${resquest}/wp-json/wp/v2/posts/${id}`)
	},
	// 根据当前文章的媒体信息id查询媒体信息
	getmedia(params) {
		return http.get(`${resquest}/wp-json/wp/v2/media/${params}`)
	},
	// 获取文章所有标签
	gettags(params) {
		return http.get(`${resquest}/wp-json/wp/v2/tags`,params)
	}
}